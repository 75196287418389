import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const MessageModal = ({icon, title, subTitle, message, children, faIcon}) => {
  return (
    <div className="message_modal bg-white clr-primary">
      {faIcon ? (
        <div className="iconWrap clr-white bg-accentBlue">
          <FontAwesomeIcon icon={icon} />
        </div>
      ) : (
        <img src={icon} alt="" />
      )}
      <h2>{title}</h2>
      <br />
      <p style={{marginBottom: "0.3rem", alignSelf: "flex-start"}}>
        <b>{subTitle}</b>
      </p>
      <p>{message}</p>
      {children}
    </div>
  );
};

export default MessageModal;

import {useState} from "react";
import uploadPic from "../assets/images/uploadPic.svg";
import TrashIcon from "../components/Icons/TrashIcon";

const UploadMultipleFile = ({text, id, errors, name, fileReg, setValue}) => {
  const [isSelected, setisSelected] = useState(false);
  const [images, setImages] = useState([]);

  function createFileList(files) {
    var b = new ClipboardEvent("").clipboardData || new DataTransfer();
    for (var i = 0, len = files.length; i < len; i++) b.items.add(files[i]);
    return b.files;
  }

  const FileChangeHandler = e => {
    if (e.target.files) {
      setImages(Array.from(e.target.files));
      let newFiles = Array.from(e.target.files).map(
        el => new File([el], el.name)
      );
      setValue(name, createFileList(newFiles));
      setisSelected(true);
    } else setisSelected(false);
  };

  const delImg = id => {
    let newImages = images.filter(el => id !== el);
    setImages(newImages);
    let newFiles = newImages.map(el => new File([el], el.name));
    setValue(name, createFileList(newFiles));
  };

  return (
    <div className="images-wrap">
      <div className="upload-file">
        <input
          type="file"
          multiple
          onClick={() => setisSelected(false)}
          onChange={e => {
            FileChangeHandler(e);
            fileReg.onChange(e);
          }}
          name={name}
          id={id}
          hidden
          ref={fileReg.ref}
          accept="image/png, image/gif, image/jpeg"
        />
        <label
          className={`${errors[name] && "is-invalid"} ${
            isSelected && "picSelected"
          }`}
          htmlFor={id}
        >
          <img src={uploadPic} alt="upload file" className="upload-pic" />
          <p>{text}</p>
        </label>
        {errors[name] && <div className="input-err-msg">Invalid input</div>}
      </div>
      <>
        {images.map((el, i) => (
          <div key={el + i} className="upload-file">
            <label>
              <img
                className="img-slt"
                src={URL.createObjectURL(el)}
                alt=" selected"
              />
              <button
                onClick={() => delImg(el)}
                type="button"
                className="edit-icon round-btn btn btn-sec"
              >
                <TrashIcon />
              </button>
            </label>
          </div>
        ))}
      </>
    </div>
  );
};
export default UploadMultipleFile;

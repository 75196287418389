import {useHistory} from "react-router";
import arrowleft from "../assets/images/arrow-left.svg";

const BackBtn = () => {
  const history = useHistory();

  return (
    <button
      onClick={() => history.go(-1)}
      className="back-icon btn round-btn bg-grey"
    >
      <img src={arrowleft} alt="arrow left" />
    </button>
  );
};

export default BackBtn;

const CardDetails = ({img, label, name, years, membershipID}) => {
  return (
    <div className="org-details bg-white">
      <img src={URL.createObjectURL(img[0])} alt="organisational logo" />
      <div className="det">
        <p className="tag bg-accentGreen clr-white">{label}</p>
        <p className="clr-primary">
          <b>{name}</b>
        </p>
        <p className="clr-primary">
          {membershipID} {years && ` • ${years} Years`}
        </p>
      </div>
    </div>
  );
};

export default CardDetails;

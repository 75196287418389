import {useForm} from "react-hook-form";
import Nav from "../components/Nav";
import ProgressBar from "../components/ProgressBar";
import {useEffect, useState} from "react";

import "../assets/styles/css/signup.css";
import ServiceDetailsForm from "../components/ServiceDetailsForm";
import displayMsg from "../components/Message";
import ReviewRequest from "../components/ReviewRequest";
import axiosInstance from "../hooks/axiosInstance";
import uploadImg from "../hooks/UploadImg";
import {productDispatch} from "./CreateProduct";
import {useHistory} from "react-router";
import SubmitBtn from "../components/SubmitBtn";

const ServiceRequest = () => {
  // register form
  const {
    formState: {errors, isValid},
    handleSubmit,
    register,
    control,
    getValues,
  } = useForm({mode: "all"});

  // register the file inputs
  const file1Reg = register("imageUrl", {required: true});

  // form submission handler
  const [reviewData, setreviewData] = useState({});
  const onError = (errors, e) => console.log(errors, e, "ui");
  const [categories, setCategories] = useState([{label: "", value: ""}]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    axiosInstance.get(`/user/getCategories/`).then(response => {

      setCategories(
        response.data.data.map(el => ({
          value: el.id,
          label: el.name,
        }))
      );
    });
  }, []);

  // form submission handler
  const onSubmit = async values => {
    let formValues;
    if (isValid) {
      try {
        setIsLoading(true);
        let url = await uploadImg(values.imageUrl[0], "jgzcovjg");
        formValues = {
          ...values,
          imageUrl: url,
          category: values.category.value,
        };
       
        productDispatch(formValues, "/startup/requestService").then(res => {
          displayMsg(res.data.status.toLowerCase(), res.data.message);
          setTimeout(() => history.go(-1), 1000);
        });
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    } else {
      console.log(
        "you have some errors in your form, Please go back to fill them"
      );
    }
  };

  // handle step-by-step form control
  const [percent, setpercent] = useState(0);
  const formname = ["Service Details", "Review"];
  const goNext = num => {
    if (isValid) {
      setpercent(percent + num);
    
      setreviewData(getValues());
    } else {
      displayMsg(
        "error",
        "Error, Please make sure all fields are filled before proceeding"
      );
      console.log(errors, "not valid");
    }
  };

  return (
    <>
      <Nav></Nav>
      <div className="pd-signup">
        <main className="pd-layout-main pd-layout-main-full bg-blue-200">
          <div className="progress-wrap">
            <ProgressBar
              className="progress"
              completed={percent}
              quadrants={1}
              names={formname}
            />
          </div>

          <form
            autoComplete="off"
            className={`form ${percent !== 1 ? "bg-white" : ""}`}
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <h1 style={{marginBottom: "0.5rem"}} className="clr-primary">
              Service Request
            </h1>
            <h2 style={{fontWeight: "500"}} className="clr-placeholder">
              Short Subheader
            </h2>
            {percent !== 1 && (
              <>
                <br />
                <div className="progress-wrap progress-wrap-mobile">
                  <ProgressBar
                    className="progress"
                    completed={percent}
                    quadrants={1}
                    names={formname}
                  />
                </div>
                <p className="clr-placeholder">Step {percent + 1} of 2</p>
                <h2 className="clr-primary">{formname[percent]}</h2>
              </>
            )}

            {percent >= 0 && (
              <ServiceDetailsForm
                display={percent === 0 ? true : false}
                errors={errors}
                register={register}
                file1Reg={file1Reg}
                control={control}
                categories={categories}
              />
            )}
            {percent >= 1 && (
              <ReviewRequest
                details={reviewData}
                display={percent === 1 ? true : false}
              />
            )}

            <div className="btn-wrap">
              <button
                onClick={() => setpercent(percent - 1)}
                className="btn btn-sec"
                type="button"
              >
                {percent === 0 ? "Cancel" : "Back"}
              </button>
              <SubmitBtn
                isLoading={isLoading}
                style={{display: percent >= 1 ? "block" : "none"}}
                btnText="Create Product"
              />
              <button
                style={{display: percent < 1 ? "block" : "none"}}
                type="button"
                onClick={() => goNext(1)}
                className="btn"
              >
                Review
              </button>
            </div>
          </form>
        </main>
      </div>
    </>
  );
};

export default ServiceRequest;

import Nav from "../components/Nav";
import bagIcon from "../assets/images/bagIcon.svg";
import "../assets/styles/css/provider.css";
import Profile from "../components/profile";
import EachService from "../components/eachService";
import {UserContext} from "../contexts/UserContextWrap";
import {useContext, useEffect, useState} from "react";
import NoProduct from "../components/NoProduct";
import Preloader from "../components/Preloader";
import Pagination from "../components/Pagination";
import useFetch from "../hooks/useFetch";
import {formatNumberToCurrency} from "../hooks/functions.js";

const StartupProfile = () => {
  const {user} = useContext(UserContext);

  // pagination
  const [curPage, setcurPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(1);

  const goNext = () => {
    setcurPage(prev => (prev < pageLimit ? prev + 1 : 1));
  };
  const goPrev = () => {
    setcurPage(prev => (prev <= 1 && prev > 0 ? pageLimit : prev - 1));
  };
  const goTo = value => {
    if (value <= pageLimit) {
      setcurPage(value);
    }
  };

  const {
    data: transactions,
    isPending,
    error,
  } = useFetch(`/user/transactions/getList?page=${curPage}`);

  useEffect(() => {
    if (transactions) {
      setPageLimit(transactions?.totalPages);
    }
  }, [transactions]);

  return (
    <div className="cover">
      <Nav></Nav>
      {isPending ? (
        <Preloader isLoading={isPending} />
      ) : (
        <div className="fn-providerProfile pd-layout">
          <main className="pd-layout-main">
            <Profile
              companyName={user.name}
              companyDescription={user.about}
              isEdit={true}
              userId={user.id}
              isService={false}
              logoUrl={user.logoUrl}
              rating={Math.round(user.rating ? user.rating : 0)}
              ratingNumber={Math.round(user.rating ? user.rating : 0)}
            />

            <div className="coloredBg">
              <div className="inner">
                {error ? (
                  <NoProduct icon={bagIcon} msg={error} />
                ) : (
                  <div className="serviceDiv">
                    <p className="title">Your Product and Service Requests</p>

                    {/* <div className="filter">
                    <button>All</button>
                    <button>Accepted</button>
                    <button>Rejected</button>
                    <button>Paid</button>
                  </div> */}
                    {transactions?.transactions?.length > 0 && (
                      <p
                        style={{fontSize: "14px", margin: "0.5rem 0"}}
                        className="clr-placeholder"
                      >
                        Page {curPage} of {pageLimit} : SHOWING{" "}
                        {transactions?.transactions?.length} RESULTS
                      </p>
                    )}
                    <div className="allService">
                      {transactions?.transactions?.length > 0 ? (
                        <>
                          {transactions.transactions.map(el => (
                            <EachService
                              key={el.id}
                              category={el.product.category.name}
                              name={el.product.name}
                              isBtn={user.userType === "Startup" ? true : false}
                              url={
                                el.isPaid
                                  ? `/provider-startup?productId=${el.id}&provider=${el.provider}`
                                  : null
                              }
                              imgUrl={el.product.imageUrl}
                              duration={`${formatNumberToCurrency(el.amount)}`}
                              btnType="prov"
                              payYet={el.isPaid}
                              payId={el.product.id}
                              btnText={el.isPaid ? "View Provider" : "Pay Now"}
                              contactedBy={el.product.contactedBy}
                              postedBy={
                                typeof el.provider === "string"
                                  ? user.logoUrl
                                  : el.provider.logoUrl
                              }
                              // postedBy={el.product.contactedBy}
                            />
                          ))}
                          <Pagination
                            cur={curPage}
                            goNext={goNext}
                            goTo={goTo}
                            goPrev={goPrev}
                            pageLimit={pageLimit}
                          />
                        </>
                      ) : (
                        <NoProduct icon={bagIcon} msg="No Transactions..." />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="featuredServices"></div>
          </main>
        </div>
      )}
    </div>
  );
};

export default StartupProfile;

import bagIcon from "../assets/images/bagIcon.svg";
import Nav from "../components/Nav";
import "../assets/styles/css/provider.css";
import Profile from "../components/profile";
import EachService from "../components/eachService";
import Wallet from "../components/wallet";
import Carousel from "../components/Carousel";
import {useContext, useEffect, useState} from "react";
import {UserContext} from "../contexts/UserContextWrap";
import axiosInstance from "../hooks/axiosInstance";
import {Link} from "react-router-dom";
import Preloader from "../components/Preloader";
import Pagination from "../components/Pagination";
import NoProduct from "../components/NoProduct";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faSpinner} from "@fortawesome/free-solid-svg-icons";
// import TrashIcon from "../components/Icons/TrashIcon";

const ProviderProfile = () => {
  // const [services, setServices] = useState(false);
  const {user} = useContext(UserContext);

  const [products, setProducts] = useState("");
  const [isPending, setIsPending] = useState(true);

  // pagination
  const [curPage, setcurPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(1);
  const goNext = () => {
    setcurPage(prev => (prev < pageLimit ? prev + 1 : 1));
  };
  const goPrev = () => {
    setcurPage(prev => (prev <= 1 && prev > 0 ? pageLimit : prev - 1));
  };
  const goTo = value => {
    if (value <= pageLimit) {
      setcurPage(value);
    }
  };
  const [error, setError] = useState(null);

  useEffect(() => {
    const abortCont = new AbortController();

    axiosInstance
      .post("/products/list", {
        providerId: user.id,
      })
      .then(result => {
        setProducts(result.data.data);
        setPageLimit(result.data.data.totalPages);
      })
      .catch(err => {
        if (err.name === "AbortError") {
          console.log("fetch aborted");
        } else if (err.message === "Failed to fetch") {
          setError(err.message + ", you might want to check your connection");
        } else {
          // auto catches network / connection error
          setError(err.message);
        }
      })
      .finally(() => {
        setIsPending(false);
      });
    // abort the fetch
    return () => abortCont.abort();
  }, [user.id]);

  // const [isDeletePending, setisDeletePending] = useState(false);
  // const delService = id => {
  //   setisDeletePending(true);
  //   axiosInstance
  //     .delete(`/provider/product/delete/${id}`)
  //     .then(res => {
  //       console.log(res);
  //     })
  //     .catch(err => console.log(err))
  //     .finally(() => setisDeletePending(false));
  // };
  // useEffect(() => {
  //   console.log(user, "kku");
  //   console.log(products, "kku");
  // }, [products, user]);

  return (
    <div className="cover">
      <Nav />
      {isPending ? (
        <Preloader isLoading={isPending} />
      ) : (
        <div className="fn-providerProfile pd-layout">
          <main className="pd-layout-main">
            <Profile
              companyName={user.name}
              logoUrl={user.logoUrl}
              companyDescription={user.about}
              isEdit={true}
              userId={user.id}
              isService={true}
              serviceList={user.categories}
              rating={Math.round(user.rating ? user.rating : 0)}
              ratingNumber={Math.round(user.rating ? user.rating : 0)}
            />
            <div className="coloredBg">
              <div className="inner">
                <div className="serviceDiv">
                  <h2 className="title">Product Gallery</h2>
                  <Carousel>
                    {user.gallery.map(el => (
                      <div key={el} className="item">
                        <img src={el} alt="products" />
                      </div>
                    ))}
                  </Carousel>
                  <br /> <br />
                  <h2 className="title">Featured Services</h2>
                  {error ? (
                    <NoProduct icon={bagIcon} msg={error} />
                  ) : (
                    <>
                      <div className="allService">
                        <p
                          style={{fontSize: "13px", margin: "0.5rem 0"}}
                          className="clr-placeholder"
                        >
                          Page {curPage} of {pageLimit} : SHOWING{" "}
                          {products.products.length} RESULTS
                        </p>
                        {products ? (
                          products.products.map(el => (
                            <EachService
                              key={el.id}
                              category={el.category.name}
                              name={el.name}
                              isBtn={true}
                              url={`/edit-product/${el.id}`}
                              imgUrl={el.imageUrl}
                              duration="2 Weeks"
                              // btnType="edit"
                              btnText="Edit"
                              postedBy={el.provider && el.provider.logoUrl}
                              contactedBy={el.contactedBy}
                            >
                              {/* <button
                            onClick={() => delService(el.id)}
                            className="btn btn-sec clr-error sm-mg"
                          >
                            <TrashIcon /> &nbsp;
                            {isDeletePending ? (
                              <FontAwesomeIcon icon={faSpinner} spin />
                            ) : (
                              "Delete"
                            )}
                          </button> */}
                            </EachService>
                          ))
                        ) : (
                          <div className="noService">
                            <div className="coverBag">
                              <img src={bagIcon} alt="bag" />
                            </div>
                            <p className="noText">
                              You don’t have any featured services yet.
                            </p>
                            <Link to="/create-product" className="noPost">
                              Post a new service
                            </Link>
                          </div>
                        )}

                        <Pagination
                          cur={curPage}
                          goNext={goNext}
                          goTo={goTo}
                          goPrev={goPrev}
                          pageLimit={pageLimit}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="featuredServices"></div>
          </main>
          <aside className="pd-layout-sidebar">
            <Wallet money={user.wallet} />
          </aside>
        </div>
      )}
    </div>
  );
};

export default ProviderProfile;

/* eslint-disable react-hooks/exhaustive-deps */
import Nav from "../../components/Nav";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {useForm} from "react-hook-form";
import Input from "../../components/Input";
import "../../assets/styles/css/accounts.css";
import EachAccount from "../../components/eachAccount";
import Pagination from "../../components/Pagination";
import {useEffect, useState} from "react";
import displayMsg from "../../components/Message";
import axiosInstance from "../../hooks/axiosInstance";
import Preloader from "../../components/Preloader";
import NoProduct from "../../components/NoProduct";
import EachWithdrawal from "../../components/EachWithdrawal";
import bagIcon from "../../assets/images/bagIcon.svg";
import {Link} from "react-router-dom";

const Accounts = () => {
  const {register, formState, handleSubmit} = useForm();
  const onSubmit = values => {
    // form is valid
    let newData =
      realData.length &&
      realData.filter(each => {
        return each.name.toLowerCase().includes(values.search.toLowerCase());
      });
    setRealData2(newData);
  };
  const [isloadingActive, setIsLoadingActive] = useState("");
  const [isLoadingVerify, setIsLoadingVerify] = useState("");

  // let { data, error, isPending } = useFetch(url);

  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);
  const [realData, setRealData] = useState([]);
  const [realData2, setRealData2] = useState([]);

  const [title, setTitle] = useState("All Accounts");
  const [showWithdrawal, setShowWithdrawal] = useState(false);

  // pagination
  const [curPage, setcurPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(6);

  const goNext = () => {
    setcurPage(prev => (prev < pageLimit ? prev + 1 : 1));
  };
  const goPrev = () => {
    setcurPage(prev => (prev <= 1 && prev > 0 ? pageLimit : prev - 1));
  };
  const goTo = value => {
    if (value <= pageLimit) {
      setcurPage(value);
    }
  };

  // pagination
  const [curPage2, setcurPage2] = useState(1);
  const [pageLimit2, setPageLimit2] = useState(1);
  const goNext2 = () => {
    setcurPage2(prev => (prev < pageLimit2 ? prev + 1 : 1));
  };
  const goPrev2 = () => {
    setcurPage2(prev => (prev <= 1 && prev > 0 ? pageLimit2 : prev - 1));
  };
  const goTo2 = value => {
    if (value <= pageLimit2) {
      setcurPage2(value);
    }
  };

  const getAllUsers = page => {
    setIsPending(true);
    const abortCont = new AbortController();
    axiosInstance
      .get(`/admin/users?page=${page}`)
      .then(json => {
        setIsPending(false);
        if (json.data.status === "Success") {
          setRealData(json.data.data.users);
          setPageLimit(json.data.data.totalPages);
          setError(null);
        } else {
          setError(json.message);
          displayMsg("error", json.message);
        }
      })
      .catch(err => {
        if (err.name === "AbortError") {
          console.log("fetch aborted");
        } else if (err.message === "Failed to fetch") {
          setIsPending(false);
          setError(err.message + ", you might want to check your connection");
          displayMsg("error", err.message);
        } else {
          // auto catches network / connection error
          setIsPending(false);
          setError(err.message);
          displayMsg("error", err.message);
        }
      });

    // abort the fetch
    return () => abortCont.abort();
  };

  const handleVerify = id => {
    setIsLoadingVerify(id);
    axiosInstance
      .put(`admin/users/verify/${id}`)
      .then(result => {
        setIsLoadingVerify("");

        if (result.data.status === "Success") {
          displayMsg("success", result.data.message);
          getAllUsers(curPage);
        } else {
          displayMsg("error", result.data.message);
          getAllUsers(curPage);
        }
      })
      .catch(error => {
        setIsLoadingVerify("");
        if (error.response) {
          displayMsg("error", error.response.data.message);
        } else if (error.request) {
          console.log(error.request);
        } else {
          displayMsg("error", error.message);
          console.log("Error", error.message);
        }
      });
  };

  const handleActivate = (id, type) => {
    setIsLoadingActive(id);
    axiosInstance
      .put(`admin/users/${type}/${id}`)
      .then(result => {
        setIsLoadingActive("");
        if (result.data.status === "Success") {
          displayMsg("success", result.data.message);
          getAllUsers(curPage);
          // history.go(0)
        } else {
          displayMsg("error", result.data.message);
          getAllUsers(curPage);
        }
      })
      .catch(error => {
        setIsLoadingActive("");
        getAllUsers(curPage);
        if (error.response) {
          displayMsg("error", error.response.data.message);
        } else if (error.request) {
          console.log(error.request);
        } else {
          displayMsg("error", error.message);
          console.log("Error", error.message);
        }
      });
  };

  const [withdrawals, setWithdrawals] = useState(null);

  useEffect(() => {
    getAllUsers(curPage);
  }, [curPage]);

  const getItems = () => {
    axiosInstance
      .get(`/admin/withdrawals?page=${curPage2}`)
      .then(res => {
        setWithdrawals(res.data.data.withdrawals);
        setPageLimit2(res.data.data.totalPages);
      })
      .catch(err => console.log(err))
      .finally(() => setIsPending(false));
  };

  useEffect(() => {
    setIsPending(true);

    getItems();
  }, [curPage2]);

  useEffect(() => {
    realData.length && setRealData2(realData);
  }, [realData]);

  const filterType = (type, pageTitle) => {
    if (type === "startup") {
      let newData =
        realData.length &&
        realData.filter(item => {
          return item.userType === "startup";
        });

      setRealData2(newData);
    } else if (type === "provider") {
      let newData =
        realData.length &&
        realData.filter(item => {
          return item.userType === "provider";
        });

      setRealData2(newData);
    }
    setShowWithdrawal(false);
    setTitle(pageTitle);
  };

  const filterActivate = (active, pageTitle) => {
    if (active === "active") {
      let newData =
        realData.length &&
        realData.filter(item => {
          return item.isActive;
        });

      setRealData2(newData);
    } else {
      let newData =
        realData.length &&
        realData.filter(item => {
          return !item.isActive;
        });

      setRealData2(newData);
    }
    setShowWithdrawal(false);
    setTitle(pageTitle);
  };

  const filterVerify = pageTitle => {
    let newData =
      realData.length &&
      realData.filter(item => {
        return !item.idVerified;
      });

    setRealData2(newData);
    setTitle(pageTitle);
    setShowWithdrawal(false);
  };

  const filterAll = () => {
    setRealData2(realData);
    setShowWithdrawal(false);
    setTitle("All Accounts");
  };
  console.log(realData2);
  return (
    <div className="accounts bg-blue-200">
      <Nav />
      {isPending ? (
        <Preloader isLoading={isPending} />
      ) : (
        <div className="mainPage">
          <div className="top">
            <div className="titleSearch center">
              <div className="tabs">
                <Link className="active" to="/admin/account">
                  <h2>All Accounts</h2>
                </Link>
                <Link to="/admin/services">
                  <h2>List Of Services</h2>
                </Link>
              </div>
              {/* <p className="title">{title}</p> */}
              <form
                style={{display: "flex"}}
                action=""
                onSubmit={handleSubmit(onSubmit)}
              >
                <Input
                  type="search"
                  name="search"
                  placeholder="Search for products and services..."
                  id="search"
                  className="search-box"
                  register={register}
                  errors={formState.errors}
                  errMsg="invalid input"
                  iconPlaceholder={faSearch}
                />
                <button
                  style={{minWidth: "50px", marginLeft: "15px"}}
                  className="btn"
                >
                  Search
                </button>
              </form>
            </div>
            <div className="filterDiv">
              <button
                onClick={filterAll}
                className={title === "All Accounts" ? "bold" : ""}
              >
                All
              </button>
              <button
                onClick={() => {
                  filterType("startup", "Startups");
                }}
                className={title === "Startups" ? "bold" : ""}
              >
                Startups
              </button>
              <button
                onClick={() => {
                  filterType("provider", "Service Providers");
                }}
                className={title === "Service Providers" ? "bold" : ""}
              >
                Service Providers
              </button>
              <button
                onClick={() => {
                  filterActivate("inactive", "Deactivated Accounts");
                }}
                className={title === "Deactivated Accounts" ? "bold" : ""}
              >
                Deactivated Accounts
              </button>
              <button
                onClick={() => {
                  filterVerify("Pending Verification");
                }}
                className={title === "Pending Verification" ? "bold" : ""}
              >
                Pending Verification
              </button>
              <button
                onClick={() => {
                  setTitle("Withdrawal Requests");
                  setShowWithdrawal(true);
                }}
                className={title === "Withdrawal Requests" ? "bold" : ""}
              >
                Withdrawal Requests
              </button>
            </div>
          </div>
          {error ? (
            <NoProduct icon={bagIcon} msg={error} />
          ) : (
            <div className="accountList">
              {showWithdrawal && withdrawals ? (
                withdrawals?.length > 0 ? (
                  <>
                    <p
                      style={{fontSize: "14px", marginBottom: "0.5rem"}}
                      className="clr-placeholder"
                    >
                      Page {curPage2} of {pageLimit2} : SHOWING{" "}
                      {withdrawals.length} RESULTS
                    </p>
                    {withdrawals.map(item => (
                      <EachWithdrawal
                        getItems={getItems}
                        key={item.id}
                        item={item}
                      />
                    ))}
                  </>
                ) : (
                  <NoProduct icon={bagIcon} msg="Oops! No withdrawals yet..." />
                )
              ) : realData2.length ? (
                realData2 && (
                  <>
                    <p
                      style={{fontSize: "14px", marginBottom: "0.5rem"}}
                      className="clr-placeholder"
                    >
                      Page {curPage} of {pageLimit} : SHOWING {realData2.length}{" "}
                      RESULTS
                    </p>
                    {realData2.map(item => {
                      return (
                        <EachAccount
                          key={item.id}
                          userType={item.userType}
                          isVerified={item.idVerified}
                          id={item.id}
                          isActive={item.isActive}
                          isLoadingVerify={isLoadingVerify === item.id}
                          isloadingActive={isloadingActive === item.id}
                          imageUrl={item.logoUrl}
                          handleActivate={handleActivate}
                          handleVerify={handleVerify}
                          accountType={
                            item.userType === "startup"
                              ? "Start up"
                              : "Provider"
                          }
                          compName={item.name}
                          rating={Math.round(item?.rating)}
                          // rating={4}
                        />
                      );
                    })}
                  </>
                )
              ) : (
                <NoProduct icon={bagIcon} msg="Oops! No Result..." />
              )}
            </div>
          )}
          {showWithdrawal ? (
            <Pagination
              cur={curPage2}
              goNext={goNext2}
              goTo={goTo2}
              goPrev={goPrev2}
              pageLimit={pageLimit2}
            />
          ) : (
            <Pagination
              cur={curPage}
              goNext={goNext}
              goTo={goTo}
              goPrev={goPrev}
              pageLimit={pageLimit}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Accounts;

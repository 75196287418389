import InputErrorMsg from "./InputErrorMsg";

const Textbox = ({
  label,
  id,
  name,
  placeholder,
  errors,
  register,
  isDisabled,
  errMsg,
  notRequired,
}) => {
  return (
    <div className="form-group">
      {label && <label htmlFor={id}>{label}</label>}
      <textarea
        className={`input-icon-wrap ${errors[name] ? "is-invalid" : ""}`}
        name={name}
        id={name}
        {...register(name, {
          required: notRequired ? false : true,
        })}
        placeholder={placeholder}
        disabled={isDisabled}
      ></textarea>
      {errors[name] && <InputErrorMsg errMsg={errMsg} />}
    </div>
  );
};

export default Textbox;

import Login from "./pages/Login";
import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import LayoutProfile from "./pages/LayoutProfile";
import ProviderProfile from "./pages/ProviderProfile";
import SignUp from "./pages/SignUp";
import StartupProfile from "./pages/StartupProfile";
import ProviderStartup from "./pages/ProviderStartup";
import ServiceRequest from "./pages/ServiceRequest";
import AllServices from "./pages/AllServices";
import Notifications from "./pages/Notifications";
import ProviderSignUp from "./pages/ProviderSignUp";
import EditProfile from "./pages/EditProfile";
import ConfirmEmail from "./pages/ConfirmEmail";
import Chats from "./pages/Chats";
import Accounts from "./pages/admin/accounts";
import AdminStartupProfile from "./pages/admin/AdminStartupProfile";
import CreateProduct from "./pages/CreateProduct";
import EditProduct from "./pages/EditProduct";
import ConfirmMemberId from "./pages/ConfirmId";
import {useContext} from "react";
import {UserContext} from "./contexts/UserContextWrap";
import PaymentSuccessful from "./pages/PaymentSuccessful";
import ScrollToTop from "./components/scrollToTop";
import AddService from "./pages/admin/AddService";
import ConfirmRequestInterest from "./pages/ConfirmRequestInterest";
// import AdminChats from "./pages/admin/adminChat";

function App() {
  const {user} = useContext(UserContext);

  const PrivateRoute = ({children, path}) => {
    return (
      <>{user ? <Route path={path}>{children}</Route> : <Redirect to="/" />}</>
    );
  };
  return (
    <BrowserRouter>
      <div className="App">
        <ScrollToTop />
        <Switch>
          <PrivateRoute path="/provider-profile">
            <ProviderProfile />
          </PrivateRoute>
          <PrivateRoute path="/provider-startup">
            <ProviderStartup />
          </PrivateRoute>
          <PrivateRoute path="/admin/services">
            <AddService />
          </PrivateRoute>
          <PrivateRoute path="/startup-profile">
            <StartupProfile />
          </PrivateRoute>
          <Route path="/layout">
            <LayoutProfile />
          </Route>
          <Route exact path="/">
            <Login />
          </Route>
          <Route exact path="/signup">
            <SignUp />
          </Route>
          <Route exact path="/signup/provider">
            <ProviderSignUp />
          </Route>
          <PrivateRoute exact path="/service-request">
            <ServiceRequest />
          </PrivateRoute>
          <PrivateRoute exact path="/all-services">
            <AllServices />
          </PrivateRoute>
          <PrivateRoute exact path="/notifications">
            <Notifications />
          </PrivateRoute>
          <PrivateRoute exact path="/edit-profile/:userId">
            <EditProfile />
          </PrivateRoute>
          <PrivateRoute exact path="/create-product">
            <CreateProduct />
          </PrivateRoute>
          <PrivateRoute exact path="/edit-product/:productId">
            <EditProduct />
          </PrivateRoute>
          <Route exact path="/confirm-email">
            <ConfirmEmail />
          </Route>
          <Route exact path="/confirm-membership">
            <ConfirmMemberId />
          </Route>
          <PrivateRoute exact path="/confirm-request">
            <ConfirmRequestInterest />
          </PrivateRoute>
          <PrivateRoute exact path="/chats">
            <Chats />
          </PrivateRoute>
          {/* <Route exact path="/admin/chats/:id">
            <AdminChats/>
          </Route> */}
          <PrivateRoute exact path="/admin/account">
            <Accounts />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/adminview">
            <AdminStartupProfile />
          </PrivateRoute>
          <PrivateRoute exact path="/payment-successful">
            <PaymentSuccessful />
          </PrivateRoute>
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;

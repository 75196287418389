import {useForm} from "react-hook-form";
import Nav from "../components/Nav";
import {useContext, useEffect, useState} from "react";
// import displayMsg from "../components/Message";

import "../assets/styles/css/signup.css";
import Textbox from "../components/Textbox";
import Input from "../components/Input";
import UploadMultipleFile from "../components/UploadMultiFiles";
import Select from "react-select";
import {Controller} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import {UserContext} from "../contexts/UserContextWrap";
import axiosInstance from "../hooks/axiosInstance";
import uploadImg from "../hooks/UploadImg";
import SubmitBtn from "../components/SubmitBtn";
import displayMsg from "../components/Message";
import {useParams} from "react-router";

const editApi = async (data, url) => {
  try {
    const res = await axiosInstance.put(url, data);
    displayMsg("success", "Profile Edited");
    return res;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      displayMsg("error", error.response.data.message);
    } else if (error.request) {
      // The request was made but no response was received
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      displayMsg("error", error.message);
    }
  }
};

const EditProfile = () => {
  const {
    formState: {errors},
    handleSubmit,
    register,
    setValue,
    control,
  } = useForm({
    mode: "all",
    shouldUnregister: true,
    reValidateMode: "onSubmit",
  });

  const {user, userDispatch} = useContext(UserContext);
  const [categories, setCategories] = useState([{label: "", value: ""}]);

  const [isLoading, setIsLoading] = useState(false);
  // register logo file input with react-hook-form
  const logoReg = register("logoUrl", {required: false});
  const MultiReg = register("gallery", {required: false});
  const {userId} = useParams();
  useEffect(() => {
    const abortCont = new AbortController();

    axiosInstance.get("/user/getCategories").then(response => {
      setCategories(
        response.data.data.map(el => ({
          value: el.id,
          label: el.name,
        }))
      );
    });
    // abort the fetch
    return () => abortCont.abort();
  }, []);

  // form submission handler
  const onSubmit = async values => {
    let formValues = {};
    let isGalleryEmpty;
    // filter out empty properties
    if (user.userType === "Provider") {
      isGalleryEmpty = values?.gallery.length === 0;
    }
    const isLogoEmpty = values.logoUrl.length === 0;
    Object.keys(values).forEach(key => {
      if (values[key] !== "" && values[key] !== undefined) {
        formValues = {...formValues, [key]: values[key]};
      }
    });
    if (isLogoEmpty) delete formValues.logoUrl;
    if (isGalleryEmpty) delete formValues.gallery;
    if (formValues?.categories?.length === 0) delete formValues.categories;
    let response;

    if (formValues) {
      try {
        setIsLoading(true);

        if (formValues.logoUrl) {
          let url = await uploadImg(formValues.logoUrl[0], "ui1agzsg");
          formValues = {
            ...formValues,
            logoUrl: url,
          };
        }
        if (formValues.gallery) {
          let urlPromises = Array.from(formValues.gallery).map(file =>
            uploadImg(file, "jgzcovjg")
          );
          let urlArr = await Promise.all(urlPromises);
          formValues = {
            ...formValues,
            gallery: urlArr,
          };
        }
        response = await editApi(
          formValues,
          `/${user.userType.toLowerCase()}/edit/${userId}`
        );

        userDispatch({
          type: "updateUserData",
          value: {...response.data.data, userType: user.userType},
        });
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const [isSelected, setisSelected] = useState(false);
  const [picSrc, setPicSrc] = useState("");

  const FileChangeHandler = e => {
    if (e.target.files && e.target.files[0]) {
      setPicSrc(URL.createObjectURL(e.target.files[0]));
      setisSelected(true);
    } else setisSelected(false);
    console.log(e.target.files, isSelected);
  };

  return (
    <>
      <Nav></Nav>
      <div className="pd-signup edit-profile">
        <main className="pd-layout-main pd-layout-main-full bg-blue-200">
          <form
            autoComplete="off"
            className={`form bg-white`}
            onSubmit={handleSubmit(onSubmit)}
          >
            <h1 className="clr-primary">Edit Profile</h1>
            <p className="clr-placeholder">ORGANIZATION DETAILS</p>
            <br />
            <div className="change-logo">
              <img className="img-slt" src={picSrc || user.logoUrl} alt="" />
              <input
                type="file"
                onClick={() => setisSelected(false)}
                onChange={e => {
                  FileChangeHandler(e);
                  logoReg.onChange(e);
                }}
                name="logoUrl"
                id="logo"
                hidden
                ref={logoReg.ref}
                accept="image/png, image/gif, image/jpeg"
              />
              <label htmlFor="logo" className="btn">
                <FontAwesomeIcon icon={faPen} />
                Change Logo
              </label>
            </div>
            <Input
              type="text"
              name="name"
              label={`Name of ${
                user.userType === "Provider" ? "Service" : "Startup"
              }`}
              placeholder="Agege inc."
              id="startupName"
              register={register}
              errors={errors}
              notRequired={true}
              errMsg="invalid input"
            />
            <Textbox
              name="about"
              label="Description"
              placeholder="Write something..."
              register={register}
              errors={errors}
              notRequired={true}
              errMsg="invalid field"
            />
            {user.userType === "Provider" && (
              <>
                <Controller
                  control={control}
                  name="categories"
                  rules={{required: false}}
                  render={({field: {onChange, value, ref}}) => (
                    <div className="form-group">
                      <label htmlFor="services">Services Rendered</label>
                      <Select
                        classNamePrefix="react-select"
                        className="react-select"
                        closeOnSelect={false}
                        id="services"
                        printOptions="on-focus"
                        isMulti
                        placeholder="Select your items"
                        options={categories}
                        value={categories.filter(
                          c => value && value.includes(c.value)
                        )}
                        onChange={val => onChange(val.map(c => c.value))}
                        ref={ref}
                      />
                    </div>
                  )}
                />
                <br />
                <h2 className="clr-primary">Product Gallery</h2>
                <UploadMultipleFile
                  text="Upload Multiple Pictures"
                  name="gallery"
                  id="multi"
                  errors={errors}
                  fileReg={MultiReg}
                  errMsg="invalid file input"
                  setValue={setValue}
                />
              </>
            )}

            <Input
              type="text"
              name="repName"
              label="Full Name"
              placeholder="Chukwuma Adebayo"
              id="repName"
              register={register}
              errors={errors}
              notRequired={true}
              errMsg="invalid name"
            />

            <Input
              type="tel"
              name="phoneNo"
              label="Phone Number"
              placeholder="+234 (0) 812 345 6789"
              id="phoneNo"
              register={register}
              errors={errors}
              notRequired={true}
              errMsg="invalid input"
            />
            <Input
              type="text"
              name="accountName"
              label="Bank Account Name"
              placeholder="Chukwuma Adebayo"
              id="accountName"
              register={register}
              errors={errors}
              notRequired={true}
              errMsg="invalid input"
            />
            <Input
              type="number"
              name="accountNo"
              label="Bank Account Number"
              placeholder="1122334455"
              id="accountNo"
              register={register}
              errors={errors}
              notRequired={true}
              errMsg="invalid input"
            />
            <Input
              type="text"
              name="bank"
              label="Bank Name"
              placeholder="Enter name of bank"
              id="bank"
              register={register}
              errors={errors}
              notRequired={true}
              errMsg="invalid input"
            />
            <div className="btn-wrap">
              <button className="btn btn-sec" type="button">
                Cancel
              </button>
              <SubmitBtn isLoading={isLoading} btnText="Save" />
            </div>
          </form>
        </main>
      </div>
    </>
  );
};

export default EditProfile;

import {createContext, useReducer} from "react";
// import axiosInstance from "../hooks/axiosInstance";

export const UserContext = createContext();

const initialUser = JSON.parse(localStorage.getItem("userData"));
const reducer = (state, action) => {
  switch (action.type) {
    case "updateUserData":
      window.localStorage.setItem("userData", JSON.stringify(action.value));
      return action.value;

    case "clearUserData":
      window.localStorage.removeItem("userData");
      return "";
    default:
      return state;
  }
};

const UserContextWrap = ({children}) => {
  const [user, userDispatch] = useReducer(reducer, initialUser);

  return (
    <UserContext.Provider value={{user, userDispatch}}>
      {children && children}
    </UserContext.Provider>
  );
};

export default UserContextWrap;

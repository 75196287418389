import Nav from "../components/Nav";
import "../assets/styles/css/notifications.css";
import MessageInfo from "../components/MessageInfo";
import BackBtn from "../components/BackBtn";
import {useState} from "react";
import axiosInstance from "../hooks/axiosInstance";
import {useEffect} from "react";
import Preloader from "../components/Preloader";
import NoProduct from "../components/NoProduct";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBellSlash} from "@fortawesome/free-solid-svg-icons";
import Pagination from "../components/Pagination";

const Notifications = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [notifications, setNotifications] = useState(null);
  // pagination
  const [curPage, setcurPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(1);

  const goNext = () => {
    setcurPage(prev => (prev < pageLimit ? prev + 1 : 1));
  };
  const goPrev = () => {
    setcurPage(prev => (prev <= 1 && prev > 0 ? pageLimit : prev - 1));
  };
  const goTo = value => {
    if (value <= pageLimit) {
      setcurPage(value);
    }
  };

  useEffect(() => {
    const abortCont = new AbortController();
    setIsLoading(true);
    const getNotifications = async page => {
      try {
        const url = `/user/notifications/getList?page=${page}`;
        let allNotifications = await axiosInstance.get(url);
        setNotifications(allNotifications.data.data.notifications);
        setPageLimit(allNotifications.data.data.totalPages);

        // filter out new notifications
        let notificationsId = allNotifications.data.data.notifications
          .filter(el => el.isRead === false)
          .map(el => el.id);

        // read new notifications
        if (notificationsId.length > 0) {
          axiosInstance.put("/user/notifications/read", {
            readNotifications: notificationsId,
          });
        }
      } catch (error) {
        if (error.response) {
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    getNotifications(curPage);

    // abort the fetch
    return () => abortCont.abort();
  }, [curPage]);

  // console.log(data,'notify')

  return (
    <div className="notifications bg-blue-200">
      <Nav />
      {isLoading ? (
        <Preloader isLoading={isLoading} />
      ) : (
        <main className="main">
          <BackBtn />
          <div className="messages-wrap">
            <h2 className="title clr-primary">Notifications</h2>
            <br />
            <p style={{fontSize: "14px"}} className="clr-placeholder">
              Page {curPage} of {pageLimit} : SHOWING {notifications.length}{" "}
              RESULTS
            </p>
            {notifications.length > 0 ? (
              notifications.map(el => (
                <MessageInfo
                  key={el.id}
                  imgSrc={el.imageUrl}
                  msg={el.description}
                  title={el.title}
                  active={!el.isRead}
                />
              ))
            ) : (
              <NoProduct style={{height: "70vh"}} msg="No Notifications...">
                <FontAwesomeIcon icon={faBellSlash} />
              </NoProduct>
            )}

            <Pagination
              cur={curPage}
              goNext={goNext}
              goTo={goTo}
              goPrev={goPrev}
              pageLimit={pageLimit}
            />
          </div>
        </main>
      )}
    </div>
  );
};

export default Notifications;

import {faCheck, faSpinner, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment";
import {useState} from "react";
// import {useHistory} from "react-router";
import axiosInstance from "../hooks/axiosInstance";
import displayMsg from "./Message";
import {formatNumberToCurrency} from "../hooks/functions.js";

const EachWithdrawal = ({item, getItems}) => {
  const [isLoadingVerify, setIsLoadingVerify] = useState("");
  const [isLoadingVerify2, setIsLoadingVerify2] = useState("");

  // const history = useHistory();
  const payWithdrawals = (id, value) => {
    if (value) {
      setIsLoadingVerify(id);
    } else {
      setIsLoadingVerify2(id);
    }
    axiosInstance
      .put(`/admin/withdrawals/confirm/${id}`, {
        isConfirmed: value,
      })
      .then(res => {
        displayMsg("success", res.data.message);
        // setTimeout(() => history.go(0), 1000);
        getItems();
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx
          displayMsg("error", error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          displayMsg("error", error.message);
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        setIsLoadingVerify("");
        setIsLoadingVerify2("");
      });
  };

  return (
    <div className="eachAccount withdrawal">
      <div className="left">
        <div
          className={`tag ${
            item.isRejected
              ? "bg-error clr-white"
              : item.isPaid
              ? "bg-accentGreen clr-white"
              : "bg-grey clr-primary"
          }`}
        >
          <p className="tagP">
            {" "}
            {moment(item.createdAt).format("DD-MMM-YYYY • h:mm")}
          </p>
        </div>
        <h2 className="clr-primary">{formatNumberToCurrency(item.amount)} </h2>
        <p className="clr-primary bank-details">
          {item.provider.bankDetails.accountName} •{" "}
          {item.provider.bankDetails.accountNo} •{" "}
          {item.provider.bankDetails.bank}
        </p>
        <small className="clr-placeholder">TRANSACTION BY</small>
        <div className="provider">
          <img src={item.provider.logoUrl} alt="" />
          <p>{item.provider.name}</p>
        </div>
      </div>
      <div className="right">
        {item.isRejected === true && item.isPaid === false ? (
          <button
            disabled={item.isRejected || isLoadingVerify2 === item.id}
            className="btn deactivate"
            onClick={() => {
              payWithdrawals(item.id, false);
            }}
          >
            <FontAwesomeIcon icon={faTimes} /> &nbsp;{" "}
            {isLoadingVerify2 === item.id ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <span> {item.isRejected ? "Rejected" : "Reject"}</span>
            )}
          </button>
        ) : (
          item.isRejected === false &&
          item.isPaid === false && (
            <button
              disabled={item.isRejected || isLoadingVerify2 === item.id}
              className="btn deactivate"
              onClick={() => {
                payWithdrawals(item.id, false);
              }}
            >
              <FontAwesomeIcon icon={faTimes} /> &nbsp;{" "}
              {isLoadingVerify2 === item.id ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <span> {item.isRejected ? "Rejected" : "Reject"}</span>
              )}
            </button>
          )
        )}

        {item.isRejected === false && (
          <button
            disabled={item.isPaid || isLoadingVerify === item.id}
            onClick={() => {
              payWithdrawals(item.id, true);
            }}
            className={`${item.isPaid ? "btn btn-sec clr-accentGreen" : "btn"}`}
          >
            <FontAwesomeIcon icon={faCheck} /> &nbsp;{" "}
            {isLoadingVerify === item.id ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <span> {item.isPaid ? "Approved" : "Approve"}</span>
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default EachWithdrawal;

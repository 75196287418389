import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {useState} from "react";
import {useHistory} from "react-router";
import axiosInstance from "../hooks/axiosInstance";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import displayMsg from "./Message";

const EachService = ({
  category,
  name,
  duration,
  isBtn,
  btnType,
  btnText,
  imgUrl,
  providerId,
  payYet,
  contactedBy,
  postedBy,
  productId,
  url,
  children,
  payId,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const handleChat = () => {
    setIsLoading(true);
    const payload = {
      providerId: providerId,
      productId: productId,
    };
    axiosInstance
      .post("/chats/init", payload)
      .then(result => {
        setIsLoading(false);

        if (result.data.status === "Success") {
          // setProduct(result.data.data);
          displayMsg("success", "Chat Initiated");
          history.push(`/chats`);
        } else {
          displayMsg("error", result.message);
        }
      })
      .catch(error => {
        setIsLoading(false);
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx

          if (
            error.response.data.message ===
            "A Chat already exists between these two users."
          ) {
            displayMsg("success", "Chat Already Initiated");
            history.push(`/chats`);
          } else {
            displayMsg("error", error.response.data.message);
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          displayMsg("error", error.message);
        }
      });
  };
  const pay = productId => {
    // setIsPay(true);
    setIsLoading(true);
    axiosInstance
      .post(`startup/pay/${productId}`)
      .then(result => {
        setIsLoading(false);

        // setIsPay(false);
        if (result.data.status === "Success") {
          // console.log(result.data.data.paymentLink)
          window.location.href = result.data.data.paymentLink;
        } else {
          displayMsg("error", result.message);
        }
      })
      .catch(error => {
        setIsLoading(false);
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx
          displayMsg("error", error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          displayMsg("error", error.message);
        }
      });
  };
  return (
    <div className="eachService">
      <div className="aboutService">
        <img className="service-img" src={imgUrl} alt="service" />
        <div className="text">
          <p className="type">{category}</p>
          <p className="serviceName">{name}</p>
          <p className="duration">{duration}</p>
          <div className="post">
            <div className="poster">
              <p>Posted By</p>
              <img src={postedBy} alt="logo" className="companyLogo" />
            </div>

            <div className="contractor">
              <p>Contacted by</p>
              <div className="allContractors">
                {contactedBy &&
                  contactedBy.map((item, i) => (
                    <img
                      key={item + i}
                      src={item}
                      alt="cpntarctor"
                      className="eachContractor"
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isBtn && url ? (
        <Link to={url} className={btnType === "edit" ? "btn btn-sec" : "btn"}>
          {btnText}
        </Link>
      ) : isBtn ? (
        <button
          onClick={
            payYet
              ? handleChat
              : payId
              ? () => {
                  pay(payId);
                }
              : handleChat
          }
          className={btnType === "edit" ? "btn btn-sec" : "btn"}
        >
          {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : btnText}
        </button>
      ) : (
        ""
      )}
      {children}
    </div>
  );
};

export default EachService;

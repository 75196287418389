import {useForm} from "react-hook-form";
import Nav from "../components/Nav";
import {useContext, useEffect, useState} from "react";
// import displayMsg from "../components/Message";

import "../assets/styles/css/signup.css";
import Textbox from "../components/Textbox";
import Input from "../components/Input";
import Upload from "../components/Upload";
import Select from "react-select";
import {Controller} from "react-hook-form";
import uploadImg from "../hooks/UploadImg";
import axiosInstance from "../hooks/axiosInstance";
import {useHistory, useParams} from "react-router";
import InputErrorMsg from "../components/InputErrorMsg";
import SubmitBtn from "../components/SubmitBtn";
import displayMsg from "../components/Message";
import {UserContext} from "../contexts/UserContextWrap";

const productDispatch = async (data, url) => {
  try {
    const res = await axiosInstance.put(url, data);
    displayMsg("success", "Product edited");

    return res;
  } catch (error) {
    if (error.response) {
      displayMsg("error", error.response.data.message);
    } else if (error.request) {
    } else {
      displayMsg("error", error.message);
    }
  }
};

const EditProduct = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([{label: "", value: ""}]);
  const [product, setProduct] = useState({});
  const {productId} = useParams();
  const history = useHistory();
  const {user} = useContext(UserContext);

  const {
    formState: {errors},
    handleSubmit,
    register,
    control,
  } = useForm({
    mode: "all",
    shouldUnregister: true,
    reValidateMode: "onSubmit",
  });

  // register logo file input with react-hook-form
  const imageUrl = register("imageUrl", {required: false});

  useEffect(() => {
    axiosInstance.get(`/user/getCategories/${user.id}`).then(response => {
      setCategories(
        response.data.data.map(el => ({
          value: el.id,
          label: el.name,
        }))
      );
    });
    axiosInstance.get(`/products/product/${productId}`).then(response => {
      setProduct(response.data.data);
    });
  }, [productId, user.id]);

  // form submission handler
  const onSubmit = async values => {
    let formValues = {};
    setIsLoading(true);

    // filter out empty properties
    let isImg = values.imageUrl.length === 0;
    Object.keys(values).forEach(key => {
      if (values[key] !== "") {
        formValues[key] = values[key];
      }
    });
    if (isImg) delete formValues.imageUrl;
    if (formValues.category)
      formValues = {...formValues, category: formValues.category.value};

    try {
      if (formValues.imageUrl) {
        let url = await uploadImg(formValues.imageUrl[0], "jgzcovjg");
        formValues = {
          ...formValues,
          imageUrl: url,
          isNegotiable: false,
        };
      }
      productDispatch(formValues, `/provider/product/edit/${productId}`).then(
        () => history.go(-1)
      );
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Nav></Nav>
      <div className="pd-signup edit-profile">
        <main className="pd-layout-main pd-layout-main-full bg-blue-200">
          <form
            autoComplete="off"
            className={`form bg-white`}
            onSubmit={handleSubmit(onSubmit)}
          >
            <h1 className="clr-primary">Edit Product</h1>
            <p className="clr-placeholder">Short Subheader</p>
            <br />

            <Input
              type="text"
              name="name"
              label="Title"
              placeholder="Petstore App Social Media Campaign"
              id="name"
              register={register}
              errors={errors}
              notRequired={true}
              errMsg="invalid input"
            />
            <Controller
              control={control}
              name="category"
              rules={{required: false}}
              render={({field: {onChange, value, ref}}) => (
                <div className="form-group">
                  <label htmlFor="services">Services Rendered</label>
                  <Select
                    classNamePrefix="react-select"
                    className="react-select"
                    closeOnSelect={false}
                    id="services"
                    printOptions="on-focus"
                    placeholder="Select your items"
                    onChange={onChange}
                    options={categories}
                    value={value}
                    ref={ref}
                  />
                  {errors.category && <InputErrorMsg errMsg="Invalid value" />}
                </div>
              )}
            />
            <Textbox
              name="description"
              label="Product Description"
              placeholder="Write something..."
              register={register}
              errors={errors}
              notRequired={true}
              errMsg="invalid field"
            />

            <p className="clr-primary">Sample Photo</p>
            {/* <br /> */}
            <Upload
              text="Upload Your Picture"
              name="imageUrl"
              id="imageUrl"
              errors={errors}
              fileReg={imageUrl}
              imgUrl={product && product.imageUrl}
              errMsg="invalid file input"
            />
            <Input
              type="number"
              name="budget"
              label="Base Price Estimate"
              placeholder="₦0"
              id="budget"
              register={register}
              errors={errors}
              notRequired={true}
              errMsg="invalid input"
            />

            <div className="btn-wrap">
              <button className="btn btn-sec" type="button">
                Cancel
              </button>
              <SubmitBtn isLoading={isLoading} btnText="Save" />
            </div>
          </form>
        </main>
      </div>
    </>
  );
};

export default EditProduct;

import Upload from "./Upload";
import Input from "./Input";
import "../assets/styles/css/signup.css";
import Checkbox from "./Checkbox";
import Select from "react-select";
import {Controller} from "react-hook-form";
import Textbox from "./Textbox";

// import {Link} from "react-router-dom";

const OrganisationProvider = ({
  register,
  errors,
  display,
  control,
  logoReg,
  options,
}) => {
  return (
    <div style={{display: display ? "block" : "none"}}>
      <p className="clr-primary">What best descibes you?</p>
      <div className="checkbox-wrapper">
        <Checkbox
          id="startup"
          name="signupType"
          value="Startup"
          linkTo="/signup"
          isChecked={false}
          label="Startup"
        />
        <Checkbox
          name="signupType"
          isChecked={true}
          value="Provider"
          id="Provider"
          linkTo="/signup/provider"
          label="Service Provider"
        />
      </div>
      <Upload
        text="Upload Your Logo"
        name="logoUrl"
        id="logoUpload"
        errors={errors}
        fileReg={logoReg}
        errMsg="invalid file input"
      />
      <br />
      <Input
        type="text"
        name="membershipId"
        label="FintechNGR Membership ID"
        placeholder="FSTR-23352"
        id="membershipID"
        register={register}
        errors={errors}
        errMsg="invalid membership ID"
      />
      <Input
        type="text"
        name="name"
        label="Name of Service"
        placeholder="Agege inc."
        id="serviceName"
        register={register}
        errors={errors}
        errMsg="invalid input"
      />
      <Controller
        control={control}
        name="categories"
        rules={{required: true}}
        render={({field: {onChange, onBlur, value, ref}}) => (
          <div className="form-group">
            <label htmlFor="services">Services Rendered</label>
            <Select
              classNamePrefix="react-select"
              className="react-select"
              closeOnSelect={false}
              id="services"
              printOptions="on-focus"
              isMulti
              placeholder="Select your items"
              options={options}
              value={options.filter(c => value && value.includes(c.value))}
              onChange={val => onChange(val.map(c => c.value))}
              ref={ref}
            />
          </div>
        )}
      />
      <Textbox
        name="about"
        label="Company Description"
        placeholder="Write something..."
        register={register}
        errors={errors}
        errMsg="invalid field"
      />
    </div>
  );
};

export default OrganisationProvider;

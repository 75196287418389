import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const SearchInput = ({searchValue, searchData, placeholder}) => {
  return (
    <div className="form-group">
      <div className="input-icon-wrap icon-placeholder">
        <button disabled className="icon icon-left">
          <FontAwesomeIcon icon={faSearch} />
        </button>
        <input
          type="search"
          name="search"
          placeholder={placeholder}
          id="search"
          className="search-box"
          value={searchValue}
          onChange={searchData}
        />
      </div>
    </div>
  );
};

export default SearchInput;

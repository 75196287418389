const SendIcon = ({fill}) => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8934 6.09515L14.8884 6.09296L1.54219 0.557334C1.42993 0.510349 1.30778 0.491922 1.18666 0.503702C1.06554 0.515481 0.949225 0.5571 0.848125 0.624834C0.741311 0.694824 0.653573 0.790272 0.592805 0.902589C0.532037 1.01491 0.500145 1.14057 0.5 1.26827V4.80858C0.50006 4.98316 0.561019 5.15225 0.67237 5.28671C0.783722 5.42116 0.938491 5.51256 1.11 5.54515L8.38906 6.89108C8.41767 6.89651 8.44348 6.91174 8.46205 6.93416C8.48062 6.95658 8.49078 6.98478 8.49078 7.0139C8.49078 7.04301 8.48062 7.07121 8.46205 7.09363C8.44348 7.11605 8.41767 7.13129 8.38906 7.13671L1.11031 8.48265C0.938851 8.51515 0.784092 8.60643 0.67269 8.74076C0.561288 8.87509 0.500219 9.04407 0.5 9.21858V12.7595C0.499917 12.8815 0.530111 13.0015 0.587871 13.1089C0.645632 13.2163 0.729152 13.3077 0.830938 13.3748C0.953375 13.4562 1.09706 13.4997 1.24406 13.4998C1.34626 13.4997 1.4474 13.4792 1.54156 13.4395L14.8875 7.93546L14.8934 7.93265C15.0731 7.85544 15.2262 7.72726 15.3337 7.56397C15.4413 7.40068 15.4986 7.20943 15.4986 7.0139C15.4986 6.81836 15.4413 6.62712 15.3337 6.46382C15.2262 6.30053 15.0731 6.17235 14.8934 6.09515Z"
        fill={fill}
      />
    </svg>
  );
};

export default SendIcon;

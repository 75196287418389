import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const MessageInfo2 = ({title, msg, imgSrc, closeChat}) => {
  return (
    <div className="info-wrap bg-white">
      <button
        aria-label="close chat"
        className="round-btn btn-sec btn"
        onClick={closeChat}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <div className={`msg-info`}>
        <img src={imgSrc} alt="info" className="info-img" />
        <div className="text">
          <h2 className="title clr-primary">{title}</h2>
          <p className="message">{msg} </p>
        </div>
      </div>
    </div>
  );
};

export default MessageInfo2;

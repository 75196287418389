import {faEnvelope, faLock} from "@fortawesome/free-solid-svg-icons";
import Input from "../components/Input";
import {useForm} from "react-hook-form";
import "../assets/styles/css/login.css";
import Nav from "../components/Nav";
import displayMsg from "../components/Message";
import {useContext, useState} from "react";
import {Redirect, useHistory} from "react-router";
import {UserContext} from "../contexts/UserContextWrap";
import axios from "axios";
import SubmitBtn from "../components/SubmitBtn";

const Login = () => {
  const {register, formState, handleSubmit} = useForm();
  const [isLoadng, setIsLoading] = useState(false);

  // import user dispatch function from token context
  const {user, userDispatch} = useContext(UserContext);

  const history = useHistory();

  const onSubmit = values => {
    // form is valid
    setIsLoading(true);

    axios
      .post("https://fintechngr-be-dev.herokuapp.com/auth/signin", values)
      .then(result => {
        setIsLoading(false);
        if (result.data.status === "Success") {
          let data = result.data.data;
          displayMsg("success", "Login Successful");
          window.localStorage.setItem("token", result.data.data.token);
          userDispatch({
            type: "updateUserData",
            value: {
              ...data[data.userType.toLowerCase()],
              userType: data.userType,
            },
          });

          window.localStorage.setItem("refToken", data.refToken);
          if (data.userType === "Admin") history.push("/admin/account");
          else history.push(`/${data.userType.toLowerCase()}-profile`);
        } else {
          displayMsg("error", result.data.message);
        }
      })
      .catch(error => {
        setIsLoading(false);
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx
          displayMsg("error", error.response.data.message);
          if (error.response.data.message.includes("verified")) {
            history.push("/confirm-membership");
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          displayMsg("error", error.message);
          console.log("Error", error.message);
        }
      });
  };

  return (
    <>
      {user ? (
        <Redirect
          to={`/${
            user.userType !== "Admin"
              ? user.userType.toLowerCase() + "-profile"
              : "admin/account"
          }`}
        />
      ) : (
        <>
          <Nav></Nav>
          <div className="pd-login">
            <main className="pd-layout-main pd-layout-main-full bg-blue-200">
              <form className="bg-white" onSubmit={handleSubmit(onSubmit)}>
                <h1 className="clr-primary">Log In</h1>
                <Input
                  type="email"
                  name="email"
                  label="Email"
                  placeholder="name@example.com"
                  id="email"
                  register={register}
                  errors={formState.errors}
                  errMsg="invalid email input"
                  iconPlaceholder={faEnvelope}
                />
                <Input
                  type="password"
                  name="password"
                  label="Password"
                  placeholder="Enter password"
                  id="password"
                  register={register}
                  iconPlaceholder={faLock}
                  errors={formState.errors}
                  errMsg="invalid password input"
                />
                <SubmitBtn isLoading={isLoadng} btnText="Log In" />
              </form>
            </main>
          </div>
        </>
      )}
    </>
  );
};

export default Login;

import {faCommentAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Input from "./Input";
import ChevronIcon from "./Icons/ChevronIcon";
import SendIcon from "./Icons/SendIcon";
import {useForm} from "react-hook-form";
import {useContext, useEffect, useRef, useState} from "react";
import {UserContext} from "../contexts/UserContextWrap";
import StarRating from "./StarRating";
import axiosInstance from "../hooks/axiosInstance";
import {faCommentSlash} from "@fortawesome/free-solid-svg-icons";
import displayMsg from "./Message";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import NoProduct from "./NoProduct";
import {formatNumberToCurrency} from "../hooks/functions.js";

const ChatBox = ({
  header,
  chatMessage,
  children,
  isRate,
  setIsRate,
  style,
  currentId,
  pay,
  isPay,
  isClose,
  productId,
  submitChat,
  adminUserId,
  closeChat,
  currentUrl,
  currentName,
  currentBudget,
  isLoading,
  person,
  chatClose,
  chatRate,
}) => {
  const {register, formState, handleSubmit, reset} = useForm({
    reValidateMode: "onChange",
    mode: "onSubmit",
    shouldUnregister: true,
  });
  const onSubmit = values => {
    submitChat(
      values.message,
      currentId,
      currentName,
      currentUrl,
      currentBudget
    );
    reset({message: ""});
  };

  const [isChatActive, setisChatActive] = useState(false);

  const {user} = useContext(UserContext);

  const handleRate = item => {
    const payload = {
      chatId: currentId,
      rating: item,
    };
    axiosInstance
      .put(`/chats/rating`, payload)
      .then(result => {
        if (result.data.status === "Success") {
          // openChat(id)
          setIsRate("");
          displayMsg("success", result.data.message);
        } else {
          setIsRate("");
          displayMsg("error", result.message);
        }
      })
      .catch(error => {
        setIsRate("");
        if (error.response) {
          displayMsg("error", error.response.data.message);
        } else if (error.request) {
          console.log(error.request);
        } else {
          displayMsg("error", error.message);
        }
      });
  };

  // scroll chats into view
  const bottomDivRef = useRef();
  const chatsRef = useRef();

  useEffect(() => {
    if (chatsRef.current && bottomDivRef.current)
      chatsRef.current.scroll({
        top: bottomDivRef.current.offsetTop,
        behavior: "smooth",
      });
  }, [chatMessage, isChatActive]);

  return (
    <div
      style={style}
      className={`chatBox ${header && isChatActive ? "showChat" : ""} ${
        header === false && "showChat"
      }`}
    >
      {isLoading ? (
        <div className="loading-chats">
          <FontAwesomeIcon icon={faSpinner} pulse spin />
          <p>Loading...</p>
        </div>
      ) : (
        <>
          {header ? (
            <header className="bg-white chat-head">
              <div className="left">
                <FontAwesomeIcon icon={faCommentAlt} />
                <h1 className="clr-primary">Chat</h1>
              </div>
              <button
                onClick={() => setisChatActive(!isChatActive)}
                className="btn round-btn bg-grey"
              >
                <ChevronIcon rotate={isChatActive ? 0 : 180} />
              </button>
            </header>
          ) : (
            <div>{children}</div>
          )}

          {/* chat body */}
          <div className="chat-body bg-blue-200">
            <div ref={chatsRef} className="chat-wrap bg-white">
              <div className="chat-heading">
                <div className="top">
                  <img
                    src={currentUrl}
                    alt="company logo"
                    className="companyLogo"
                  />{" "}
                  <div className="title">
                    <h3 className="clr-primary">{currentName}</h3>
                    <p className="details">
                      {currentBudget
                        ? currentBudget === "empty"
                          ? null
                          : `${formatNumberToCurrency(currentBudget)}`
                        : "***"}
                    </p>
                  </div>
                </div>
                {user.userType === "Admin" ? null : (
                  <div className="bottom">
                    <div className="checkbox-wrapper">
                      {user.userType === "Provider" ? null : person ? null : (
                        <button
                          style={{width: "150px"}}
                          onClick={() => {
                            pay(productId);
                          }}
                          className="btn"
                        >
                          {isPay ? (
                            <FontAwesomeIcon icon={faSpinner} spin />
                          ) : (
                            " Pay Now"
                          )}
                        </button>
                      )}
                    </div>

                    <button
                      onClick={() => {
                        closeChat(currentId);
                      }}
                      className={`btn btn-sec ${
                        user.userType === "Admin" || chatClose
                          ? "clr-error"
                          : ""
                      } `}
                      disabled={user.userType === "Admin" || chatClose}
                    >
                      {isClose ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : user.userType === "Admin" || chatClose ? (
                        "Chat Closed"
                      ) : (
                        "Close"
                      )}
                    </button>
                  </div>
                )}
              </div>
              <div className="chats">
                {chatMessage.length ? (
                  chatMessage.map(item => {
                    return (
                      <article
                        key={item.id}
                        className={
                          item.sender === user.id || item.sender === adminUserId
                            ? `chat sender`
                            : `chat receive`
                        }
                      >
                        <p
                          className={
                            item.sender === user.id ||
                            item.sender === adminUserId
                              ? `bg-primary clr-white`
                              : `bg-grey`
                          }
                        >
                          {item.content}
                        </p>
                      </article>
                    );
                  })
                ) : (
                  <div className="chat-body">
                    <NoProduct msg="No Chats Yet...">
                      <FontAwesomeIcon icon={faCommentSlash} />
                    </NoProduct>
                  </div>
                )}
                {chatRate === true || chatRate === false ? (
                  isRate === currentId ? (
                    <div className="rateComponent">
                      <p>RATE THIS CONVERSATION</p>
                      <StarRating
                        disabled={false}
                        starClass={"bigger"}
                        onChange={handleRate}
                      />
                    </div>
                  ) : null
                ) : null}

                {chatRate === true || chatRate === false ? (
                  !chatRate && chatClose ? (
                    <div className="rateComponent">
                      <p>RATE THIS CONVERSATION</p>
                      <StarRating
                        disabled={false}
                        starClass={"bigger"}
                        onChange={handleRate}
                      />
                    </div>
                  ) : null
                ) : null}
              </div>
              <div ref={bottomDivRef}></div>
            </div>
          </div>
          {/* chat footer */}
          <div className="chat-footer bg-white">
            {user.userType === "Admin" || chatClose ? null : (
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* <button className="btn round-btn bg-grey">
                  <AttachIcon />
                </button>
                <button className="btn round-btn bg-grey">
                  <ImgIcon />
                </button> */}

                <Input
                  type="text"
                  name="message"
                  placeholder="Type a Message..."
                  id="message"
                  register={register}
                  errors={formState.errors}
                />
                <button className="btn round-btn bg-accentGreen">
                  <SendIcon fill="white" />
                </button>
              </form>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ChatBox;

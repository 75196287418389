import Upload from "./Upload";
import Input from "./Input";
import Select from "react-select";
import "../assets/styles/css/signup.css";
import {Controller} from "react-hook-form";
import Textbox from "./Textbox";
import InputErrorMsg from "./InputErrorMsg";

const ServiceDetailsForm = ({
  register,
  errors,
  display,
  control,
  file1Reg,
  file2Reg,
  categories,
}) => {
  return (
    <div style={{display: display ? "block" : "none"}}>
      <br />
      <Input
        type="text"
        name="name"
        label="Title"
        placeholder="Petstore App Social Media Campaign"
        id="membershipID"
        register={register}
        errors={errors}
        errMsg="invalid membership ID"
      />

      <Controller
        control={control}
        name="category"
        rules={{required: false}}
        render={({field: {onChange, value, ref}}) => (
          <div className="form-group">
            <label htmlFor="services">Services Rendered</label>
            <Select
              classNamePrefix="react-select"
              className="react-select"
              closeOnSelect={false}
              id="services"
              printOptions="on-focus"
              placeholder="Select your items"
              onChange={onChange}
              options={categories}
              value={value}
              ref={ref}
            />
            {errors.category && <InputErrorMsg errMsg="Invalid value" />}
          </div>
        )}
      />
      <Textbox
        name="description"
        label="Project Description"
        placeholder="Write something..."
        register={register}
        errors={errors}
        errMsg="invalid field"
      />

      <p className="clr-primary">Sample Photos</p>
      <div className="flex-box">
        <Upload
          text="Upload Your Photo"
          name="imageUrl"
          id="photo1"
          errors={errors}
          // register={register}
          fileReg={file1Reg}
          errMsg="invalid file input"
        />
        {/* <Upload
          text="Upload Your Photo"
          name="photo2"
          id="photo2"
          fileReg={file2Reg}
          errors={errors}
          errMsg="invalid file input"
        /> */}
      </div>
      <Input
        type="number"
        name="budget"
        label="Budget Amount"
        placeholder="Enter amount in naira (₦)"
        id="budget"
        register={register}
        errors={errors}
        errMsg="invalid Budget Amount"
      />
    </div>
  );
};

export default ServiceDetailsForm;

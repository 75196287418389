import {useState} from "react";
import uploadPic from "../assets/images/uploadPic.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";

const UploadFile = ({text, id, errors, name, fileReg, imgUrl}) => {
  const [isSelected, setisSelected] = useState(false);
  const [picSrc, setPicSrc] = useState("");

  const FileChangeHandler = e => {
    if (e.target.files && e.target.files[0]) {
      setPicSrc(URL.createObjectURL(e.target.files[0]));
      setisSelected(true);
    } else setisSelected(false);
  };

  return (
    <div className="upload-file">
      <input
        type="file"
        onClick={() => setisSelected(false)}
        onChange={e => {
          FileChangeHandler(e);
          fileReg.onChange(e);
        }}
        name={name}
        id={id}
        hidden
        ref={fileReg.ref}
        accept="image/png, image/gif, image/jpeg"
      />
      <label
        className={`${errors[name] && "is-invalid"} ${
          isSelected && "picSelected"
        }`}
        htmlFor={id}
      >
        {imgUrl && !isSelected ? (
          <>
            <img className="img-slt" src={imgUrl} alt=" selected" />
            <p className="edit-icon round-btn btn bg-grey">
              <FontAwesomeIcon icon={faPen} />
            </p>
          </>
        ) : isSelected ? (
          <>
            <img className="img-slt" src={picSrc} alt=" selected" />
            <p className="edit-icon round-btn btn bg-grey">
              <FontAwesomeIcon icon={faPen} />
            </p>
          </>
        ) : (
          <>
            <img
              src={uploadPic || imgUrl}
              alt="upload file"
              className="upload-pic"
            />
            <p>{text}</p>
          </>
        )}
      </label>
      {errors[name] && <div className="input-err-msg">Invalid input</div>}
    </div>
  );
};
export default UploadFile;

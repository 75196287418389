import {Link} from "react-router-dom";
import InputErrorMsg from "./InputErrorMsg";

const Checkbox = ({
  name,
  onClick,
  value,
  id,
  label,
  readOnly,
  register,
  errors,
  errMsg,
  isChecked,
  linkTo,
}) => {
  return (
    <>
      <div
        style={{background: isChecked ? "#052769" : "#edf1f8"}}
        className="checkbox-wrap"
      >
        {register ? (
          <>
            <input
              // readOnly={readOnly}
              checked={isChecked}
              onClick={onClick}
              value={value}
              type="radio"
              name={name}
              {...register(name, {
                required: true,
              })}
              id={id}
            />
            <label htmlFor={id}>{label}</label>
          </>
        ) : (
          <>
            <Link to={linkTo}>
              <input
                readOnly={true}
                checked={isChecked}
                value={value}
                type="radio"
                name={name}
                id={id}
              />
              <label htmlFor={id}>{label}</label>
            </Link>
          </>
        )}
      </div>
      {errors && errors[name] && <InputErrorMsg errMsg={errMsg} />}
    </>
  );
};

export default Checkbox;

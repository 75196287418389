/* eslint-disable react-hooks/exhaustive-deps */
// import {useState} from "react";
import Nav from "../../components/Nav";
// import arrowleft from "../assets/images/arrow-left.svg";
// import providerIcon from "../assets/images/provider-icon.svg";
// import pen from "../assets/images/pen.svg";
// import message from "../assets/images/message.svg";
// import StarRating from "../components/StarRating";
// import service from "../assets/images/service.png";
// import bagIcon from "../assets/images/bagIcon.svg";
import "../../assets/styles/css/provider.css";
import Profile from "../../components/profile";
import EachService from "../../components/eachService";
import {useEffect, useState} from "react";
import useFetch from "../../hooks/useFetch";
import bagIcon from "../../assets/images/bagIcon.svg";
import axiosInstance from "../../hooks/axiosInstance";
import displayMsg from "../../components/Message";
import Preloader from "../../components/Preloader";
import NoProduct from "../../components/NoProduct";
import Pagination from "../../components/Pagination";

import {formatNumberToCurrency} from "../../hooks/functions.js";

const AdminStartupProfile = () => {
  // const [services, setServices] = useState(false);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");
  const type = urlParams.get("type");
  const [url] = useState(`/user/getDetails/${id}`);
  let {data, error, isPending} = useFetch(url);
  const [product, setProduct] = useState([]);
  const [transaction, setTransaction] = useState([]);
  // pagination
  const [curPage, setcurPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(1);

  const goNext = () => {
    setcurPage(prev => (prev < pageLimit ? prev + 1 : 1));
  };
  const goPrev = () => {
    setcurPage(prev => (prev <= 1 && prev > 0 ? pageLimit : prev - 1));
  };
  const goTo = value => {
    if (value <= pageLimit) {
      setcurPage(value);
    }
  };

  // pagination
  const [curPage2, setcurPage2] = useState(1);
  const [pageLimit2, setPageLimit2] = useState(1);
  const goNext2 = () => {
    setcurPage2(prev => (prev < pageLimit2 ? prev + 1 : 1));
  };
  const goPrev2 = () => {
    setcurPage2(prev => (prev <= 1 && prev > 0 ? pageLimit2 : prev - 1));
  };
  const goTo2 = value => {
    if (value <= pageLimit) {
      setcurPage2(value);
    }
  };

  const pullProduct = (type, page, page2) => {
    if (type === "Provider") {
      const payload = {
        providerId: id,
      };
      axiosInstance
        .post(`/products/list?page=${page}`, payload)
        .then(result => {
          if (result.data.status === "Success") {
            setProduct(result.data.data.products);
            setPageLimit(result.data.data.totalPages);
          } else {
            displayMsg("error", result.message);
          }
        })
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code that falls out of the range of 2xx
            displayMsg("error", error.response.data.message);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            displayMsg("error", error.message);
          }
        });
    } else {
      axiosInstance
        .get(`/admin/transactions/getList/${id}?page=${page2}`)
        .then(result => {
          if (result.data.status === "Success") {
            setTransaction(result.data.data.transactions);
            setPageLimit2(result.data.data.totalPages);
          } else {
            displayMsg("error", result.message);
          }
        })
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code that falls out of the range of 2xx
            displayMsg("error", error.response.data.message);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            displayMsg("error", error.message);
          }
        });
    }
  };

  useEffect(() => {
    pullProduct(type, curPage, curPage2);
  }, [curPage]);

  return (
    <div className="cover">
      <Nav />
      {isPending ? (
        <Preloader isLoading={isPending} />
      ) : (
        <div className="fn-providerProfile pd-layout">
          {error ? (
            <p>{error}</p>
          ) : (
            <main className="pd-layout-main">
              <Profile
                companyName={data && data.name}
                companyDescription={data && data.about}
                isEdit={false}
                logoUrl={data && data.logoUrl}
                textEdit={"View chats"}
                linkTo={"viewChart"}
                id={data && data.id}
                isService={false}
                rating={data && Math.round(data.rating)}
                ratingNumber={data && Math.round(data.rating)}
              />
              <div className="coloredBg">
                <div className="inner">
                  <div className="serviceDiv">
                    <p className="title">Users Product and Service Requests</p>

                    <div className="allService">
                      {type === "Provider" ? (
                        product.length ? (
                          product && (
                            <>
                              <p
                                style={{
                                  fontSize: "13px",
                                  margin: "0.5rem 0",
                                }}
                                className="clr-placeholder"
                              >
                                Page {curPage} of {pageLimit} : SHOWING{" "}
                                {product.length} RESULTS
                              </p>
                              {product.map(item => {
                                return (
                                  <EachService
                                    category={
                                      item.category && item.category.name
                                    }
                                    name={item.name}
                                    isBtn={false}
                                    key={item.id}
                                    postedBy={
                                      item.provider && item.provider.logoUrl
                                    }
                                    contactedBy={item.contactedBy}
                                    imgUrl={item.imageUrl}
                                    duration={`₦ ${item.budget}`}
                                  />
                                );
                              })}
                            </>
                          )
                        ) : (
                          <NoProduct
                            msg="There are no featured services."
                            icon={bagIcon}
                          />
                        )
                      ) : transaction.length ? (
                        transaction && (
                          <>
                            <p
                              style={{fontSize: "13px", margin: "0.5rem 0"}}
                              className="clr-placeholder"
                            >
                              Page {curPage2} of {pageLimit2} : SHOWING{" "}
                              {transaction.length} RESULTS
                            </p>
                            {transaction.map(item => {
                              return (
                                <EachService
                                  category={
                                    item.product && item.product.category.name
                                  }
                                  name={item.product && item.product.name}
                                  isBtn={false}
                                  key={item.id}
                                  postedBy={item.postedBy}
                                  contactedBy={item.contactedBy}
                                  imgUrl={item.product && item.product.imageUrl}
                                  duration={`${
                                    item.product &&
                                    formatNumberToCurrency(item.product.budget)
                                  }`}
                                />
                              );
                            })}
                          </>
                        )
                      ) : (
                        <NoProduct
                          msg="There are no featured services."
                          icon={bagIcon}
                        />
                      )}
                      {type === "Provider" ? (
                        <Pagination
                          cur={curPage}
                          goNext={goNext}
                          goTo={goTo}
                          goPrev={goPrev}
                          pageLimit={pageLimit}
                        />
                      ) : (
                        <Pagination
                          cur={curPage2}
                          goNext={goNext2}
                          goTo={goTo2}
                          goPrev={goPrev2}
                          pageLimit={pageLimit2}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="featuredServices"></div>
            </main>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminStartupProfile;

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faSpinner} from "@fortawesome/free-solid-svg-icons";
import EyeClosedIcon from "../components/Icons/EyeClosedIcon";
import {useContext, useState} from "react";
import flutter from "../assets/images/flutterwave.svg";
import displayMsg from "./Message";
import axiosInstance from "../hooks/axiosInstance";
import {useForm} from "react-hook-form";
import Input from "./Input";
import {UserContext} from "../contexts/UserContextWrap";

const Wallet = ({money}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isForm, setIsForm] = useState(false);
  const [type, setType] = useState("withdraw");
  const [isLoadigwith, setIsLoadingwith] = useState(false);
  const {register, formState, handleSubmit} = useForm();
  const [moneyData, setMoneyData] = useState(money);

  const {user, userDispatch} = useContext(UserContext);

  const [url] = useState(`/user/getDetails/${user.id}`);

  const getUserDet = async () => {
    try {
      let json = await axiosInstance.get(url);

      if (json.data.status === "Success") {
        setMoneyData(json.data.data.wallet);
        console.log(json.data.data.wallet);
        if (user?.userType === "Provider") {
          let newCategories = await axiosInstance.get(
            `/user/getCategories/${user.id}`
          );
          userDispatch({
            type: "updateUserData",
            value: {...json.data.data, categories: newCategories.data.data},
          });
          window.localStorage.setItem(
            "userData",
            JSON.stringify({
              ...json.data.data,
              categories: newCategories.data.data,
            })
          );
        } else {
          userDispatch({
            type: "updateUserData",
            value: {...json.data.data},
          });
          window.localStorage.setItem(
            "userData",
            JSON.stringify(json.data.data)
          );
        }
      } else {
        displayMsg("error", json.message);
      }
    } catch (err) {
      if (err.name === "AbortError") {
        console.log("fetch aborted");
      } else if (err.message === "Failed to fetch") {
        displayMsg("error", err.message);
      } else {
        // auto catches network / connection error
        displayMsg("error", err.message);
      }
    }
  };

  const handleForm = type => {
    if (type === "withdraw") {
      setType("withdraw");
      setIsForm(!isForm);
    } else {
      setType("deposit");
      setIsForm(!isForm);
    }
  };

  const handleVisible = () => {
    setIsVisible(!isVisible);
  };

  const onSubmit = values => {
    // form is valid

    setIsLoadingwith(true);
    let url = type === "withdraw" ? "/provider/withdraw" : "/provider/deposit";
    axiosInstance
      .post(url, values)
      .then(result => {
        setIsLoadingwith(false);

        if (result.data.status === "Success") {
          // let data = result.data.data;
          displayMsg("success", "Successful");
          getUserDet();
        } else {
          displayMsg("error", result.data.message);
        }
      })
      .catch(error => {
        setIsLoadingwith(false);
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx
          displayMsg("error", error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          displayMsg("error", error.message);
          console.log("Error", error.message);
        }
      });
  };

  return (
    <div className="wallet">
      <p className="title">Account</p>
      <div className="walletFlex">
        <div className="balance">
          <p className="spell">BALANCE</p>
          <div className="money">
            <p>
              {" "}
              &#8358; <span>{isVisible ? moneyData : "***"}</span>
            </p>
          </div>
        </div>
        <div onClick={handleVisible} className="show">
          {isVisible === true ? (
            <FontAwesomeIcon icon={faEye} />
          ) : (
            <EyeClosedIcon />
          )}
        </div>
      </div>
      <div className="actions">
        <button
          className="withdraw expand"
          onClick={() => {
            handleForm("withdraw");
          }}
        >
          Withdraw
        </button>
        {/* <button className="deposit" onClick={()=>{handleForm("deposit")}}>Deposit</button> */}
      </div>

      <div className="form">
        {isForm && (
          <form className="bg-white" onSubmit={handleSubmit(onSubmit)}>
            <Input
              type="number"
              name="amount"
              label="Enter Amount"
              placeholder="20000"
              id="email"
              register={register}
              errors={formState.errors}
              errMsg="invalid email input"
              // iconPlaceholder={faEnvelope}
            />
            <button disabled={isLoadigwith} className="btn expand">
              {isLoadigwith ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : type === "withdraw" ? (
                "Submit"
              ) : (
                "Deposit"
              )}
            </button>
          </form>
        )}
      </div>

      <div className="flutterBox">
        <img src={flutter} alt="flutter icon" />
        <p>
          Secured by <span>Flutterwave</span>
        </p>
      </div>
    </div>
  );
};

export default Wallet;

import CardDetails from "./CardDetails";
const ReviewRequest = ({details: {name, category, imageUrl, description}}) => {
  return (
    <div className="reveiw-details">
      <br />
      <CardDetails label={category.label} name={name} img={imageUrl} />
      <br />
      <h2 className="clr-primary">Description</h2>
      <p className="clr-primary">{description}</p>
      <br />
    </div>
  );
};

export default ReviewRequest;

import {useEffect, useState} from "react";

const ProgressBar = props => {
  const {completed, quadrants, names} = props;
  const [percent, setPercent] = useState(0);
  // const [basic,setBasic] = useState([]);

  useEffect(() => {
    setPercent(Math.floor((completed / quadrants) * 100));
  }, [completed, quadrants]);

  // styles...
  const containerStyles = {
    height: `${55 * (quadrants + 1)}px`,
    position: "sticky",
    top: "10vh",
    width: "2px",
    backgroundColor: "#BAC9E3",
  };

  const fillerStyles = {
    width: "100%",
    backgroundColor: "#93E396",
    borderRadius: "inherit",
    textAlign: "right",
  };

  return (
    <div className="progress" style={containerStyles}>
      {/* first circle */}
      <div className="quadrant-wrap">
        <span
          style={{
            background: completed >= 0 ? "#93E396" : "white",
            borderColor: completed >= 0 ? "#93E396" : "#BAC9E3",
          }}
        ></span>
        <p className={`clr-primary ${completed >= 0 ? "active" : ""}`}>
          {names[0]}
        </p>
      </div>

      {/* generate circles */}
      {[...Array(quadrants)].map((el, i) => {
        return (
          <div
            className="quadrant-wrap"
            style={{
              top: `${Math.floor(((i + 1) / quadrants) * 100)}%`,
            }}
            key={i + 1}
          >
            <span
              style={{
                background: completed >= i + 1 ? "#93E396" : "white",
                borderColor: completed >= i + 1 ? "#93E396" : "#e0e0de",
              }}
            ></span>
            <p className={`clr-primary ${completed >= i + 1 ? "active" : ""}`}>
              {names[i + 1]}
            </p>
          </div>
        );
      })}

      {/* filler div */}
      <div
        className="quadrant"
        style={{
          ...fillerStyles,
          height: `${percent}%`,
        }}
        role="progressbar"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  );
};

export default ProgressBar;

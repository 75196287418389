// import { useState } from "react";
// import Nav from "../components/Nav";
import pen from "../assets/images/pen.svg";
import message from "../assets/images/message.svg";
import StarRating from "../components/StarRating";
import BackBtn from "./BackBtn";
import {Link} from "react-router-dom";
import {useHistory} from "react-router";
// import {useContext} from "react";
// import {UserContext} from "../contexts/UserContextWrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
const Profile = ({
  companyName,
  companyDescription,
  isEdit,
  isService,
  serviceList,
  rating,
  isChatOpener,
  linkTo,
  initChat,
  ratingNumber,
  textEdit,
  logoUrl,
  isLoading,
  id,
  userId,
}) => {
  // const {user} = useContext(UserContext);

  const history = useHistory();

  const handleLink = () => {
    if (linkTo === "viewChart") {
      history.push(`/chats?id=${id}`);
    }
    if (isChatOpener) {
      initChat();
    }
  };
  return (
    <div className="profile">
      <BackBtn />
      <div className="inner">
        <div className="profileContent">
          <div className="aboutCompany">
            <div className="top">
              <img src={logoUrl} alt="company logo" className="companyLogo" />
              <div className="texts">
                <p className="company-name">{companyName}</p>
                <p className="companydescripption">{companyDescription}</p>
                {isEdit ? (
                  <Link to={`/edit-profile/${userId}`}>
                    <button className="btn-sec btn editProfile">
                      <img src={pen} alt="edit icon" />
                      Edit Profile
                    </button>
                  </Link>
                ) : (
                  <button
                    style={{cursor: "pointer"}}
                    onClick={handleLink}
                    className="btn-sec editProfile send"
                  >
                    {isLoading ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      <div className="cover">
                        <img src={message} alt="edit icon" />
                        {textEdit}
                      </div>
                    )}
                  </button>
                )}
              </div>
            </div>
            <div className="sm-btn">
              {isEdit ? (
                <Link
                  to={`/edit-profile/${userId}`}
                  className="btn-sec btn editProfile"
                >
                  <button className="btn-sec btn editProfile">
                    <img src={pen} alt="edit icon" />
                    Edit Profile
                  </button>
                </Link>
              ) : (
                <button className="btn-sec editProfile send">
                  <img src={message} alt="edit icon" />
                  Send a Message
                </button>
              )}
            </div>
          </div>
          {isService && (
            <div className="company-Services">
              <p className="title">Services</p>
              <div className="allService">
                {serviceList.map((item, index) => {
                  return (
                    <p key={index} className="eachService">
                      {item.name}
                    </p>
                  );
                })}
              </div>
            </div>
          )}

          <div className="company-Rating">
            <p className="title">Rating</p>
            {rating ? (
              <p className="number"> {rating} </p>
            ) : (
              <strong className="clr-primary"> N/A </strong>
            )}
            <StarRating stars={rating} disabled={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;

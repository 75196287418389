import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";

const Carousel = ({children}) => {
  const SlickArrowLeft = ({currentSlide, slideCount, ...props}) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <span className="btn round-btn bg-grey slide-arrow">
        <FontAwesomeIcon icon={faChevronLeft} />
      </span>
    </button>
  );
  const SlickArrowRight = ({currentSlide, slideCount, ...props}) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <span className="btn round-btn bg-grey slide-arrow">
        <FontAwesomeIcon icon={faChevronRight} />
      </span>
    </button>
  );

  let options = {
    // dots: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // autoplay: true,
          // autoplaySpeed: 2000,
        },
      },
    ],
  };
  return (
    <div className="carousel-wrap">
      <Slider {...options}>{children}</Slider>
    </div>
  );
};

export default Carousel;

import Input from "../components/Input";
import {faEnvelope, faLock} from "@fortawesome/free-solid-svg-icons";

import "../assets/styles/css/signup.css";

const PersonalDetails = ({register, display, errors}) => {
  return (
    <div style={{display: display ? "block" : "none"}}>
      <br />
      <Input
        type="text"
        name="repName"
        label="Full Name"
        placeholder="Chukwuma Adebayo"
        id="repName"
        register={register}
        errors={errors}
        errMsg="invalid name"
      />
      <Input
        type="email"
        name="email"
        label="Email"
        placeholder="name@example.com"
        id="email"
        register={register}
        errors={errors}
        errMsg="invalid email input"
        iconPlaceholder={faEnvelope}
      />

      <Input
        type="tel"
        name="phoneNo"
        label="Phone Number"
        placeholder="+234 (0) 812 345 6789"
        id="phoneNo"
        register={register}
        errors={errors}
        errMsg="invalid input"
      />
      <Input
        type="password"
        name="password"
        label="Password"
        placeholder="Enter password"
        id="password"
        register={register}
        errors={errors}
        iconPlaceholder={faLock}
        errMsg="invalid password input"
      />
    </div>
  );
};

export default PersonalDetails;

import StarRating from "../components/StarRating";
import deletePen from "../assets/images/delete.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import markicon from "../assets/images/markicon.svg";
import "../assets/styles/css/eachAccount.css";
import {useHistory} from "react-router";

const EachAccount = ({
  accountType,
  compName,
  rating,
  isActive,
  isVerified,
  handleActivate,
  id,
  imageUrl,
  isloadingActive,
  isLoadingVerify,
  handleVerify,
}) => {
  const history = useHistory();
  const handleView = () => {
    history.push(`/admin/adminview?id=${id}&type=${accountType}`);
  };

  return (
    <div className="eachAccount">
      <div className="left">
        <p className="accountType">{accountType}</p>
        <div className="companyLogo">
          <img src={imageUrl} alt="company logo" className="companyLogo" />
          <div className="aboutComp">
            <p className="compName">{compName}</p>
            <StarRating stars={rating} disabled={true} />
          </div>
        </div>
      </div>
      <div className="right">
        {!isVerified && (
          <button
            onClick={() => {
              handleVerify(id);
            }}
            className="deactivate btn"
          >
            <img src={markicon} alt="pen icon" />

            {isLoadingVerify ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              "Verify Account"
            )}
          </button>
        )}

        {isActive ? (
          <button
            onClick={() => {
              handleActivate(id, "deactivate");
            }}
            className="deactivate btn"
          >
            <img src={deletePen} alt="pen icon" />

            {isloadingActive ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              "Deactivate Account"
            )}
          </button>
        ) : (
          <button
            onClick={() => {
              handleActivate(id, "activate");
            }}
            className="deactivate btn"
          >
            {isloadingActive ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              " Activate Account"
            )}
          </button>
        )}

        <button className="view btn" onClick={handleView}>
          View
        </button>
      </div>
    </div>
  );
};

export default EachAccount;

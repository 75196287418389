import {useForm} from "react-hook-form";
import Nav from "../components/Nav";
import {useContext, useEffect, useState} from "react";
// import displayMsg from "../components/Message";

import "../assets/styles/css/signup.css";
import Textbox from "../components/Textbox";
import Input from "../components/Input";
import Upload from "../components/Upload";
import Select from "react-select";
import {Controller} from "react-hook-form";
import uploadImg from "../hooks/UploadImg";
import axiosInstance from "../hooks/axiosInstance";
import displayMsg from "../components/Message";
import {useHistory} from "react-router";
import {UserContext} from "../contexts/UserContextWrap";
import SubmitBtn from "../components/SubmitBtn";

export const productDispatch = async (data, url) => {
  try {
   
    const res = await axiosInstance.post(url, data);
    
    return res;
  } catch (error) {
    if (error.response) {
      displayMsg("error", error.response.data.message);
    } else if (error.request) {
      console.log(error.request);
    } else {
      displayMsg("error", error.message);
     
    }
  }
};

const CreateProduct = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([{label: "", value: ""}]);
  const history = useHistory();
  const {
    formState: {errors, isValid},
    handleSubmit,
    register,
    control,
  } = useForm({
    mode: "all",
    shouldUnregister: true,
    reValidateMode: "onChange",
  });

  const {user} = useContext(UserContext);
  // register logo file input with react-hook-form
  const imageUrl = register("imageUrl", {required: true});

  useEffect(() => {
    axiosInstance.get(`/user/getCategories/${user.id}`).then(response => {
      setCategories(
        response.data.data.map(el => ({
          value: el.id,
          label: el.name,
        }))
      );
    });
  }, [user.id]);

  // form submission handler
  const onSubmit = async values => {
    let formValues;
    if (isValid) {
      try {
        setIsLoading(true);
        let url = await uploadImg(values.imageUrl[0], "jgzcovjg");
        formValues = {
          ...values,
          imageUrl: url,
          category: values.category.value,
          isNegotiable: false,
        };
      
        productDispatch(formValues, "/provider/product/create").then(() =>
          history.go(-1)
        );
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    } else {
      console.log(
        "you have some errors in your form, Please go back to fill them"
      );
    }
  };

  return (
    <>
      <Nav></Nav>
      <div className="pd-signup edit-profile">
        <main className="pd-layout-main pd-layout-main-full bg-blue-200">
          <form
            autoComplete="off"
            className={`form bg-white`}
            onSubmit={handleSubmit(onSubmit)}
          >
            <h1 className="clr-primary">Create Product</h1>
            <p className="clr-placeholder">Short Subheader</p>
            <br />

            <Input
              type="text"
              name="name"
              label="Title"
              placeholder="Petstore App Social Media Campaign"
              id="name"
              register={register}
              errors={errors}
              required={false}
              errMsg="invalid input"
            />
            <Controller
              control={control}
              name="category"
              rules={{required: true}}
              render={({field: {onChange, value, ref}}) => (
                <div className="form-group">
                  <label htmlFor="services">Services Rendered</label>
                  <Select
                    classNamePrefix="react-select"
                    className="react-select"
                    closeOnSelect={false}
                    id="services"
                    printOptions="on-focus"
                    placeholder="Select your items"
                    onChange={onChange}
                    options={categories}
                    value={value}
                    ref={ref}
                  />
                </div>
              )}
            />
            <Textbox
              name="description"
              label="Product Description"
              placeholder="Write something..."
              register={register}
              errors={errors}
              required={false}
              errMsg="invalid field"
            />

            <p className="clr-primary">Sample Photo</p>
            {/* <br /> */}
            <Upload
              text="Upload Your Picture"
              name="imageUrl"
              id="imageUrl"
              errors={errors}
              fileReg={imageUrl}
              errMsg="invalid file input"
            />
            <Input
              type="number"
              name="budget"
              label="Base Price Estimate"
              placeholder="₦0"
              id="budget"
              register={register}
              errors={errors}
              required={false}
              errMsg="invalid input"
            />

            <div className="btn-wrap">
              <button className="btn btn-sec" type="button">
                Cancel
              </button>
              <SubmitBtn isLoading={isLoading} btnText="Create Product" />
            </div>
          </form>
        </main>
      </div>
    </>
  );
};

export default CreateProduct;

import axios from "axios";
import decode from "jwt-decode";

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  baseURL: "https://fintechngr-be-dev.herokuapp.com/",
});

axiosInstance.interceptors.request.use(
  config => {
    const refToken = localStorage.getItem("refToken") || "";
    let token = localStorage.getItem("token") || "";
    if (token && refToken) {
      const decoded = decode(token);
      if (new Date(Number(decoded.exp + "000")) < new Date()) {
        axios
          .get("https://fintechngr-be-dev.herokuapp.com/auth/refreshToken", {
            headers: {
              refreshToken: refToken,
            },
          })
          .then(response => {
            token = response.data.data.token;
            localStorage.setItem("token", response.data.data.token);
            localStorage.setItem("refToken", response.data.data.refToken);
            window.location.reload();
          })
          .catch(err => {
            console.log(err);
          });
      }
      config.headers = {
        ...config.headers,
        token: token,
      };
    }
    return config;
  },
  err => {
    console.log(err);
    return err;
  }
);

export default axiosInstance;

const EyeClosedIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.22807 13.0214L4.94074 12.6761L5.46541 10.7167C4.68005 10.4271 3.95015 10.005 3.30741 9.46874L1.87207 10.9047L0.92874 9.9614L2.36474 8.52607C1.55412 7.55524 1.00946 6.39058 0.784073 5.14607L2.09607 4.90674C2.60207 7.70807 5.05274 9.8334 8.00007 9.8334C10.9467 9.8334 13.3981 7.70807 13.9041 4.90674L15.2161 5.1454C14.991 6.39008 14.4466 7.55498 13.6361 8.52607L15.0714 9.9614L14.1281 10.9047L12.6927 9.46874C12.05 10.005 11.3201 10.4271 10.5347 10.7167L11.0594 12.6767L9.77207 13.0214L9.24674 11.0614C8.42164 11.2028 7.57851 11.2028 6.75341 11.0614L6.22807 13.0214Z"
        fill="#052769"
      />
    </svg>
  );
};

export default EyeClosedIcon;

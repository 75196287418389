import MessageModal from "../components/MessageModal";
import Nav from "../components/Nav";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
const ConfirmRequestInterest = () => {
  return (
    <>
      <Nav />
      <div className="pd-login">
        <main className="pd-layout-main pd-layout-main-full bg-blue-200">
          <MessageModal
            icon={faCheck}
            faIcon={true}
            subTitle="What’s next"
            title="Your interest in the service has been sent to the startup"
          >
            <p>
              Wait for a response from the startup who requested the services,
              as they have been notified by your interest in the service
            </p>
          </MessageModal>
        </main>
      </div>
    </>
  );
};

export default ConfirmRequestInterest;

const NotificationIcon = ({newNotification}) => {
  return (
    <div className={newNotification ? "icon-active" : ""}>
      <svg
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.40814 1.16528C5.50775 0.919091 5.67861 0.708254 5.89882 0.559798C6.11903 0.411342 6.37856 0.332031 6.64414 0.332031C6.90972 0.332031 7.16925 0.411342 7.38946 0.559798C7.60967 0.708254 7.78053 0.919091 7.88014 1.16528C8.86598 1.4364 9.73555 2.02365 10.3553 2.83684C10.9751 3.65002 11.3108 4.64418 11.3108 5.66662V8.79795L12.5321 10.6299C12.5991 10.7303 12.6376 10.8471 12.6435 10.9676C12.6493 11.0882 12.6223 11.208 12.5654 11.3145C12.5085 11.4209 12.4237 11.5098 12.3201 11.5719C12.2166 11.6339 12.0982 11.6666 11.9775 11.6666H8.95414C8.87387 12.222 8.59618 12.7299 8.17194 13.0972C7.7477 13.4646 7.20532 13.6668 6.64414 13.6668C6.08297 13.6668 5.54058 13.4646 5.11634 13.0972C4.6921 12.7299 4.41441 12.222 4.33414 11.6666H1.31081C1.19011 11.6666 1.07168 11.6339 0.968137 11.5719C0.864595 11.5098 0.779833 11.4209 0.722893 11.3145C0.665954 11.208 0.638972 11.0882 0.644828 10.9676C0.650684 10.8471 0.689157 10.7303 0.756143 10.6299L1.97748 8.79795V5.66662C1.97748 3.51728 3.43081 1.70662 5.40814 1.16528ZM5.70148 11.6666C5.77033 11.8617 5.898 12.0307 6.06689 12.1502C6.23578 12.2697 6.43758 12.3338 6.64448 12.3338C6.85137 12.3338 7.05317 12.2697 7.22206 12.1502C7.39095 12.0307 7.51862 11.8617 7.58748 11.6666H5.70081H5.70148ZM6.64414 2.33328C5.76009 2.33328 4.91224 2.68447 4.28712 3.30959C3.662 3.93471 3.31081 4.78256 3.31081 5.66662V8.99995C3.31084 9.13164 3.27187 9.26038 3.19881 9.36995L2.55681 10.3333H10.7308L10.0888 9.36995C10.016 9.26031 9.97725 9.13157 9.97748 8.99995V5.66662C9.97748 4.78256 9.62629 3.93471 9.00116 3.30959C8.37604 2.68447 7.5282 2.33328 6.64414 2.33328Z"
          fill="#052769"
        />
      </svg>
    </div>
  );
};

export default NotificationIcon;

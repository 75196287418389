import {
  faExclamationTriangle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";
import EachService from "../components/eachService";
import Input from "../components/Input";
import Nav from "../components/Nav";
import "../assets/styles/css/all-services.css";
import Pagination from "../components/Pagination";
import {useContext, useState} from "react";
import {useEffect} from "react";
import {UserContext} from "../contexts/UserContextWrap";
import axiosInstance from "../hooks/axiosInstance";
import useFetch from "../hooks/useFetch";
import Preloader from "../components/Preloader";
import NoProduct from "../components/NoProduct";
import bagIcon from "../assets/images/bagIcon.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {formatNumberToCurrency} from "../hooks/functions.js";
const AllServices = () => {
  const {register, formState, handleSubmit} = useForm();
  const onSubmit = values => {
    // form is valid

    let newProduct = products.filter(each => {
      return each.name.toLowerCase().includes(values.search.toLowerCase());
    });

    setRealProducts(newProduct);
  };
  const {user} = useContext(UserContext);
  const [url] = useState(`/user/getCategories`);

  // pagination
  const [curPage, setcurPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(1);
  const goNext = () => {
    setcurPage(prev => (prev < pageLimit ? prev + 1 : 1));
  };
  const goPrev = () => {
    setcurPage(prev => (prev <= 1 && prev > 0 ? pageLimit : prev - 1));
  };
  const goTo = value => {
    if (value <= pageLimit) {
      setcurPage(value);
    }
  };
  let {data, error, isPending} = useFetch(url);
  const [products, setProducts] = useState("");
  const [realProducts, setRealProducts] = useState([]);

  const handleSelect = item => {
    if (item === "all") {
      setRealProducts(products);
    } else {
      let newProduct = products.filter(each => {
        return item === each.category.name;
      });
      setRealProducts(newProduct);
    }
  };

  useEffect(() => {
    axiosInstance
      .post(`/products/list?page=${curPage}`)
      .then(result => {
        setProducts(result.data.data.products);
        setPageLimit(result.data.data.totalPages);
      })
      .catch(error => console.log("error", error));
  }, [curPage]);

  useEffect(() => {
    products && setRealProducts(products);
  }, [products]);

  const clearFilter = () => {
    setRealProducts(products);
  };

  return (
    <div className="all-services  bg-blue-200">
      <Nav />
      {isPending ? (
        <Preloader isLoading={isPending} />
      ) : (
        <div className="pd-layout">
          {error ? (
            <NoProduct
              className="error"
              style={{height: "calc(100vh - 70px)"}}
              msg={error}
            >
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </NoProduct>
          ) : (
            <main className="pd-layout-main">
              <div className="inner">
                <section className="top-section">
                  <div className="right">
                    <h1 className="clr-primary">All Services</h1>
                    <form action="" onSubmit={handleSubmit(onSubmit)}>
                      <Input
                        type="search"
                        name="search"
                        placeholder="Search for products and services..."
                        id="search"
                        className="search-box"
                        register={register}
                        errors={formState.errors}
                        errMsg="invalid email input"
                        iconPlaceholder={faSearch}
                      />
                      <div className="filter-wrap">
                        <p>Filters:</p>
                        {/* <button className="btn btn-sec">Category</button> */}
                        <select
                          name=""
                          onChange={e => {
                            handleSelect(e.target.value);
                          }}
                          className="btn btn-sec"
                          id=""
                        >
                          <option value="all">All Category</option>
                          {data &&
                            data.map(item => {
                              return (
                                <option key={item.id} value={item.name}>
                                  {item.name}
                                </option>
                              );
                            })}
                        </select>
                        <button
                          className="btn btn-sec"
                          type="button"
                          onClick={clearFilter}
                        >
                          Clear
                        </button>
                        <button className="btn btn-sec">Search</button>
                      </div>
                    </form>
                  </div>
                  <div className="left">
                    <Link
                      className="btn clr-primary bg-accentGreen"
                      to={
                        user.userType === "Startup"
                          ? "/service-request"
                          : "/create-product"
                      }
                    >
                      {user.userType === "Startup"
                        ? "Request A Service"
                        : "Create Product"}
                    </Link>
                  </div>
                </section>

                <p style={{fontSize: "14px"}} className="clr-placeholder">
                  Page {curPage} of {pageLimit} : SHOWING {realProducts.length}{" "}
                  RESULTS
                </p>
                <div className="service-wrap">
                  {realProducts.length ? (
                    realProducts.length &&
                    realProducts.map(el => (
                      <EachService
                        key={el.id}
                        category={el.category.name}
                        name={el.name}
                        isBtn={user.userType === "Startup" ? true : false}
                        url={`/provider-startup?productId=${el.id}&provider=${el.provider.id}`}
                        imgUrl={el.imageUrl}
                        postedBy={el.provider && el.provider.logoUrl}
                        contactedBy={el.contactedBy}
                        duration={`${
                          el.budget ? formatNumberToCurrency(el.budget) : "***"
                        }`}
                        btnType="prov"
                        btnText="View Provider"
                      />
                    ))
                  ) : (
                    <NoProduct icon={bagIcon} msg="Result Not Found" />
                  )}
                </div>
                <Pagination
                  cur={curPage}
                  goNext={goNext}
                  goTo={goTo}
                  goPrev={goPrev}
                  pageLimit={pageLimit}
                />
              </div>
            </main>
          )}
          {/* <aside className="pd-layout-sidebar bg-white">
          <h2>sidebar</h2>
        </aside> */}
        </div>
      )}
    </div>
  );
};

export default AllServices;

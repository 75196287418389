/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react-hooks/exhaustive-deps */
import Nav from "../components/Nav";
import {useForm} from "react-hook-form";
import Input from "../components/Input";
import {io} from "socket.io-client";
import {
  faCommentSlash,
  faExclamationTriangle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import MessageInfo3 from "../components/MessageInfo3";
import MessageInfo2 from "../components/MessageInfo2";
import ChatBox from "../components/ChatBox";
import "../assets/styles/css/Chats.css";
import {useContext, useRef, useState} from "react";
import useFetch from "../hooks/useFetch";
import displayMsg from "../components/Message";
import BackBtn from "../components/BackBtn";
import {UserContext} from "../contexts/UserContextWrap";
import axiosInstance from "../hooks/axiosInstance";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Preloader from "../components/Preloader";
import NoProduct from "../components/NoProduct";
import {useEffect} from "react";

const Chats = () => {
  const {register, formState, handleSubmit} = useForm();

  const [chatMessage, setChatMessage] = useState([]);
  const [currentId, setCurrentId] = useState("");
  const [currentUrl, setCurrentUrl] = useState("");
  const [currentName, setCurrentName] = useState("");
  const [currentBudget, setCurrentBudget] = useState("");
  const [currentChatClose, setCurrentChatClose] = useState("");
  const [currentChatRate, setCurrentChatRate] = useState("");
  const [productId, setProductId] = useState("");
  const [isRate, setIsRate] = useState("");
  const [person, setPerson] = useState(false);
  const [isPay, setIsPay] = useState("");
  const [isClose, setIsClose] = useState("");
  const {user} = useContext(UserContext);
  const [isFirst, setIsFirst] = useState(false);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");
  const socketRef = useRef(null);
  const [secData, setSecData] = useState([]);

  console.log(secData, "secData");

  const [url] = useState(
    user.userType === "Admin" ? `/admin/chats/${id}` : `/chats/getList`
  );
  const handleisFirst = () => {
    setIsFirst(true);
  };

  const [showChat, setshowChat] = useState(false);

  let {data, error, isPending} = useFetch(url);

  useEffect(() => {
    setSecData(data);
  }, [data]);

  const onSubmit = values => {
    // form is valid

    let newChat = data.filter(each => {
      return each.provider.name
        .toLowerCase()
        .includes(values.search.toLowerCase());
    });

    setSecData(newChat);
  };

  const submitChat = (text, id, currentName, currentUrl, budget, productId) => {
    const payload = {
      content: text,
      chatId: id,
    };
    axiosInstance
      .post("/chats/message", payload)
      .then(result => {
        if (result.data.status === "Success") {
          // openChat(id, currentName, currentUrl, budget, productId);
        } else {
          displayMsg("error", result.message);
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx
          displayMsg("error", error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          displayMsg("error", error.message);
        }
      });
  };

  const [isChatLoading, setIsChatLoading] = useState(true);
  const openChat = (
    id,
    currentName,
    currentUrl,
    budget,
    productId,
    person,
    chatClose,
    chatRate
  ) => {
    setCurrentUrl(currentUrl);
    setCurrentName(currentName);
    setCurrentBudget(budget);
    setProductId(productId);
    setCurrentId(id);
    setPerson(person);
    setCurrentChatRate(chatRate);
    setCurrentChatClose(chatClose);
    setIsChatLoading(true);
    let url = `/chats/messages/${id}`;
    const abortCont = new AbortController();
    axiosInstance
      .get(url)
      .then(json => {
        if (json.data.status === "Success") {
          setChatMessage(json.data.data);
        } else {
          displayMsg("error", json.message);
        }
      })
      .catch(err => {
        if (err.name === "AbortError") {
          console.log("fetch aborted");
        } else if (err.message === "Failed to fetch") {
          displayMsg("error", err.message);
        } else {
          // auto catches network / connection error
          displayMsg("error", err.message);
        }
      })
      .finally(() => setIsChatLoading(false));

    // abort the fetch
    return () => abortCont.abort();
  };

  // sockett
  useEffect(() => {
    if (currentId) {
      socketRef.current = io("https://fintechngr-be-dev.herokuapp.com/", {
        query: {chatId: currentId},
      });

      socketRef.current.on("connect", () => {
        console.log(`Connected to ID ${socketRef.current.id}`);
      });

      socketRef.current.on("newMessage", newMsg => {
        setChatMessage(prev => {
          return [...prev, newMsg];
        });
      });

      return () => {
        socketRef.current.disconnect();
      };
    }
  }, [currentId]);

  const closeChat = id => {
    setIsClose(true);
    axiosInstance
      .put(`/chats/close/${id}`)
      .then(result => {
        setIsClose(false);

        if (result.data.status === "Success") {
          // openChat(id)
          setIsRate(id);
          displayMsg("success", result.data.message);
        } else {
          displayMsg("error", result.message);
        }
      })
      .catch(error => {
        setIsClose(false);
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx
          displayMsg("error", error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          displayMsg("error", error.message);
        }
      });
  };

  const pay = productId => {
    setIsPay(true);
    axiosInstance
      .post(`startup/pay/${productId}`)
      .then(result => {
        setIsPay(false);
        if (result.data.status === "Success") {
          // console.log(result.data.data.paymentLink)
          window.location.href = result.data.data.paymentLink;
        } else {
          displayMsg("error", result.message);
        }
      })
      .catch(error => {
        setIsPay(false);
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx
          displayMsg("error", error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          displayMsg("error", error.message);
        }
      });
  };

  const [chatActive, setchatActive] = useState("");

  return (
    <>
      <Nav />
      {isPending ? (
        <Preloader isLoading={isPending} />
      ) : (
        <div className="pd-chats bg-blue-200">
          <BackBtn />
          {error ? (
            <NoProduct
              className="error"
              style={{height: "calc(100vh - 70px)"}}
              msg={error}
            >
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </NoProduct>
          ) : (
            <main>
              <section className="chats-info clr-primary">
                <div className="chat-title">
                  <BackBtn />
                  <h2 className="chat-text">Chat</h2>
                </div>
                <form style={{}} action="" onSubmit={handleSubmit(onSubmit)}>
                  <Input
                    type="search"
                    name="search"
                    placeholder="Search chats"
                    id="search"
                    className="search-box except"
                    register={register}
                    errors={formState.errors}
                    errMsg="invalid email input"
                    iconPlaceholder={faSearch}
                  />
                  <button style={{width: "25%"}} className="btn btn-sec">
                    Search
                  </button>
                </form>
                <div className="chat-info-wrap bg-white">
                  {secData ? (
                    secData.length ? (
                      secData.map(item => {
                        return (
                          <MessageInfo3
                            active={item.id === chatActive}
                            key={item.id}
                            handleisFirst={handleisFirst}
                            // imgSrc={item.product && item.product.imageUrl}
                            imgSrc={
                              item.product
                                ? item.product.imageUrl
                                : id === item.provider.id ||
                                  user.id === item.provider.id
                                ? item.startup.logoUrl
                                : item.provider.logoUrl
                            }
                            // imgSrc={
                            //   id === item.provider.id ||
                            //   user.id === item.provider.id
                            //     ? item.startup.logoUrl
                            //     : item.provider.logoUrl
                            // }
                            isPerson={item.product ? false : true}
                            budget={
                              item.product ? item.product.budget : "empty"
                            }
                            productSrc={
                              item.product
                                ? item.product.imageUrl
                                : id === item.provider.id ||
                                  user.id === item.provider.id
                                ? item.startup.logoUrl
                                : item.provider.logoUrl
                            }
                            openChat={openChat}
                            chatId={item.id}
                            chatClose={item.isClosed}
                            chatRate={
                              user.userType === "Provider"
                                ? item.hasProviderRated
                                : item.hasStartupRated
                            }
                            productId={item.product && item.product.id}
                            userId={
                              id === item.provider.id ||
                              user.id === item.provider.id
                                ? item.startup.id
                                : item.provider.id
                            }
                            // msg="Last Message"
                            showChat={() => {
                              setshowChat(!showChat);
                              setchatActive(item.id);
                            }}
                            // title={
                            //   id === item.provider.id ||
                            //   user.id === item.provider.id
                            //     ? item.startup.name
                            //     : item.provider.name
                            // }
                            title={
                              item.product
                                ? item.product.name
                                : id === item.provider.id ||
                                  user.id === item.provider.id
                                ? item.startup.name
                                : item.provider.name
                            }
                            productTitle={
                              item.product
                                ? item.product.name
                                : id === item.provider.id ||
                                  user.id === item.provider.id
                                ? item.startup.name
                                : item.provider.name
                            }
                          />
                        );
                      })
                    ) : (
                      <NoProduct msg="Empty Chat List...">
                        <FontAwesomeIcon icon={faCommentSlash} />
                      </NoProduct>
                    )
                  ) : (
                    <NoProduct msg="Empty Chat List...">
                      <FontAwesomeIcon icon={faCommentSlash} />
                    </NoProduct>
                  )}
                </div>
              </section>
              <section
                className={`chatbox-wrapper ${showChat ? "show-chat" : ""}`}
              >
                {isFirst ? (
                  <ChatBox
                    currentId={currentId}
                    adminUserId={id}
                    pay={pay}
                    isPay={isPay}
                    isClose={isClose}
                    productId={productId}
                    isRate={isRate}
                    setIsRate={setIsRate}
                    currentBudget={currentBudget}
                    currentName={currentName}
                    currentUrl={currentUrl}
                    person={person}
                    closeChat={closeChat}
                    submitChat={submitChat}
                    chatMessage={chatMessage}
                    style={{height: "100%", maxHeight: "100%"}}
                    header={false}
                    isLoading={isChatLoading}
                    chatClose={currentChatClose}
                    chatRate={currentChatRate}
                  >
                    <MessageInfo2
                      imgSrc={currentUrl}
                      msg=""
                      title={currentName}
                      closeChat={() => setshowChat(!showChat)}
                    />
                  </ChatBox>
                ) : (
                  <NoProduct msg="No Chats Yet...">
                    <FontAwesomeIcon icon={faCommentSlash} />
                  </NoProduct>
                )}
              </section>
            </main>
          )}
        </div>
      )}
    </>
  );
};

export default Chats;

import MessageModal from "../components/MessageModal";
import Nav from "../components/Nav";
import warning from "../assets/images/warning.svg";
const ConfirmMemberId = () => {
  return (
    <>
      <Nav />
      <div className="pd-login">
        <main className="pd-layout-main pd-layout-main-full bg-blue-200">
          <MessageModal
            icon={warning}
            subTitle="What’s next"
            title="Your email has been verified but your Membership ID has not been approved yet."
          >
            <p>
              Your <b>Membership ID </b> will be confirmed shortly by the admin
              and you will be notified when it’s confirmed. Until then, your
              account will not be active.
            </p>
          </MessageModal>
        </main>
      </div>
    </>
  );
};

export default ConfirmMemberId;

/* eslint-disable react-hooks/exhaustive-deps */
import Nav from "../components/Nav";
import "../assets/styles/css/provider.css";
import bagIcon from "../assets/images/bagIcon.svg";
import Profile from "../components/profile";
import {io} from "socket.io-client";
import ChatBox from "../components/ChatBox";
import Wallet from "../components/wallet";
import EachService from "../components/eachService";
import {useContext, useEffect, useRef} from "react";
import {UserContext} from "../contexts/UserContextWrap";
import {useState} from "react";
import useFetch from "../hooks/useFetch";
import {useHistory} from "react-router";
import axiosInstance from "../hooks/axiosInstance";
import displayMsg from "../components/Message";
import Pagination from "../components/Pagination";
import Preloader from "../components/Preloader";
import {formatNumberToCurrency} from "../hooks/functions.js";

const ProviderStartup = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const provider = urlParams.get("provider");
  // const productId = urlParams.get("productId");

  const {user} = useContext(UserContext);
  const [url] = useState(`/user/getDetails/${provider}`);
  let {data, isPending} = useFetch(url);
  const [serviceList, setServiceList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [isClose] = useState("");
  const [chatMessage, setChatMessage] = useState([]);
  const [chatClose, setChatClose] = useState([]);
  const [isRate, setIsRate] = useState("");
  const socketRef = useRef(null);

  const [chatInfo, setChatInfo] = useState("");
  // startup/getProviderNonProductChat/614bc144d6ef5cbf866c381c

  // pagination
  const [curPage, setcurPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(1);
  const goNext = () => {
    setcurPage(prev => (prev < pageLimit ? prev + 1 : 1));
  };
  const goPrev = () => {
    setcurPage(prev => (prev <= 1 && prev > 0 ? pageLimit : prev - 1));
  };
  const goTo = value => {
    if (value <= pageLimit) {
      setcurPage(value);
    }
  };

  const [product, setProduct] = useState([]);
  const history = useHistory();
  const pullChart = id => {
    if (id) {
      axiosInstance
        .post(`startup/getProviderNonProductChat/${id}`)
        .then(result => {
          if (result.data.status === "Success") {
            setCurrentId(result.data.data.id);
            setChatClose(result.data.data.isClosed);
            setChatInfo(result.data.data);
          } else {
            // displayMsg("error", result.message);
          }
        })
        .catch(error => {
          console.log(error, "resutt");
          if (error.response) {
            // The request was made and the server responded with a status code that falls out of the range of 2xx
            // displayMsg("error", error.response.data.message);
          } else if (error.request) {
            console.log(error.request);
          } else {
            displayMsg("error", error.message);
          }
        });
    }
  };

  useEffect(() => {
    data && pullChart(data.id);
  }, [data]);

  const pullProduct = (type, page) => {
    if (type === "Provider") {
      const payload = {
        providerId: provider,
      };
      axiosInstance
        .post(`/products/list?page=${page}`, payload)
        .then(result => {
          if (result.data.status === "Success") {
            setProduct(result.data.data.products);
            setPageLimit(result.data.data.totalPages);
          } else {
            displayMsg("error", result.message);
          }
        })
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code that falls out of the range of 2xx
            displayMsg("error", error.response.data.message);
          } else if (error.request) {
            console.log(error.request);
          } else {
            displayMsg("error", error.message);
          }
        });
    } else {
    }
  };

  const getService = provider => {
    let url = `/user/getCategories/${provider}`;
    const abortCont = new AbortController();
    axiosInstance
      .get(url)
      .then(json => {
        if (json.data.status === "Success") {
          setServiceList(json.data.data);
        } else {
          displayMsg("error", json.message);
        }
      })
      .catch(err => {
        if (err.name === "AbortError") {
          console.log("fetch aborted");
        } else if (err.message === "Failed to fetch") {
          displayMsg("error", err.message);
        } else {
          // auto catches network / connection error
          displayMsg("error", err.message);
        }
      });

    // abort the fetch
    return () => abortCont.abort();
  };

  const initChat = () => {
    setIsLoading(true);
    const payload = {
      providerId: provider,
    };
    axiosInstance
      .post("/chats/init", payload)
      .then(result => {
        setIsLoading(false);
        if (result.data.status === "Success") {
          displayMsg("success", "Chat Initiated");
          setCurrentId(result.data.data.id);
          openChat(result.data.data.id);
          pullChart(data.id);
        } else {
          displayMsg("error", result.message);
        }
      })
      .catch(error => {
        setIsLoading(false);

        if (error.response) {
          if (
            error.response.data.message ===
            "A Chat already exists between these two users."
          ) {
            displayMsg("success", "Chat Already Initiated");
            history.push(`/chats`);
          } else {
            displayMsg("error", error.response.data.message);
          }
        } else if (error.request) {
          console.log(error.request);
        } else {
          displayMsg("error", error.message);
        }
      });
  };

  const closeChat = id => {
    axiosInstance
      .put(`/chats/close/${id}`)
      .then(result => {
        if (result.data.status === "Success") {
          // openChat(id)
          setIsRate(id);
          displayMsg("success", result.data.message);
        } else {
          displayMsg("error", result.message);
        }
      })
      .catch(error => {
        if (error.response) {
          displayMsg("error", error.response.data.message);
        } else if (error.request) {
          console.log(error.request);
        } else {
          displayMsg("error", error.message);
        }
      });
  };

  const openChat = id => {
    // setCurrentId(id)
    let url = `/chats/messages/${id}`;
    const abortCont = new AbortController();
    axiosInstance
      .get(url)
      .then(json => {
        if (json.data.status === "Success") {
          setChatMessage(json.data.data);
        } else {
          displayMsg("error", json.message);
        }
      })
      .catch(err => {
        if (err.name === "AbortError") {
          console.log("fetch aborted");
        } else if (err.message === "Failed to fetch") {
          displayMsg("error", err.message);
        } else {
          // auto catches network / connection error
          displayMsg("error", err.message);
        }
      });

    // abort the fetch
    return () => abortCont.abort();
  };

  useEffect(() => {
    currentId && openChat(currentId);
  }, [currentId]);

  // sockett
  useEffect(() => {
    if (currentId) {
      socketRef.current = io("https://fintechngr-be-dev.herokuapp.com/", {
        query: {chatId: currentId},
      });

      socketRef.current.on("connect", () => {
        console.log(`Connected to ID ${socketRef.current.id}`);
      });

      socketRef.current.on("newMessage", newMsg => {
        setChatMessage(prev => {
          return [...prev, newMsg];
        });
      });

      return () => {
        socketRef.current.disconnect();
      };
    }
  }, [currentId]);

  const submitChat = (text, id) => {
    const payload = {
      content: text,
      chatId: id,
    };

    axiosInstance
      .post("/chats/message", payload)
      .then(result => {
        if (result.data.status === "Success") {
          // openChat(id);
        } else {
          displayMsg("error", result.message);
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx
          displayMsg("error", error.response.data.message);
        } else if (error.request) {
          console.log(error.request);
        } else {
          displayMsg("error", error.message);
        }
      });
  };

  useEffect(() => {
    pullProduct("Provider", curPage);
    getService(provider);
  }, [curPage]);

  return (
    <div className="cover">
      <Nav></Nav>
      {isPending ? (
        <Preloader isLoading={isPending} />
      ) : (
        <div className="fn-providerProfile pd-layout">
          <main className="pd-layout-main">
            <Profile
              companyName={data && data.name}
              companyDescription={data && data.about}
              isEdit={false}
              id={provider}
              initChat={initChat}
              serviceList={serviceList && serviceList}
              logoUrl={data && data.logoUrl}
              isService={user.userType === "Startup" ? true : false}
              textEdit={"Chat"}
              isLoading={isLoading}
              isChatOpener={true}
              linkTo={""}
              rating={data ? Math.round(data.rating ? data.rating : 0) : 0}
              ratingNumber={
                data ? Math.round(data.rating ? data.rating : 0) : 0
              }
            />
            <div className="coloredBg">
              <div className="inner">
                <div className="serviceDiv">
                  <p className="title">Past Products and Services</p>
                  <p
                    style={{fontSize: "14px", marginBottom: "10px"}}
                    className="clr-placeholder"
                  >
                    Page {curPage} of {pageLimit} : SHOWING {product.length}{" "}
                    RESULTS
                  </p>
                  <div className="allService">
                    {product.length ? (
                      product &&
                      product.map(item => {
                        return (
                          <EachService
                            category={item.category && item.category.name}
                            name={item.name}
                            isBtn={true}
                            key={item.id}
                            productId={item.id}
                            providerId={provider}
                            btnText={"Bargain"}
                            contactedBy={item.contactedBy}
                            postedBy={item.provider && item.provider.logoUrl}
                            imgUrl={item.imageUrl}
                            duration={`${
                              item.budget
                                ? formatNumberToCurrency(item.budget)
                                : 0
                            }`}
                          />
                        );
                      })
                    ) : (
                      <div className="noService">
                        <div className="coverBag">
                          <img src={bagIcon} alt="bag" />
                        </div>
                        <p className="noText">
                          There are no featured services.
                        </p>
                      </div>
                    )}

                    <Pagination
                      cur={curPage}
                      goNext={goNext}
                      goTo={goTo}
                      goPrev={goPrev}
                      pageLimit={pageLimit}
                    />

                    {/* <div className="noService">
                    <div className="coverBag">
                      <img src={bagIcon} alt="bag" />
                    </div>
                    <p className="noText">You don’t have any featured services yet.</p>
                    <button className="noPost">Post a new service</button>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="featuredServices"></div>
          </main>
          <aside className="pd-layout-sidebar flex-end">
            {user.userType === "Startup" ? null : <Wallet money={"240.78"} />}

            <ChatBox
              currentId={currentId}
              chatClose={chatClose}
              chatRate={
                user.userType === "Provider"
                  ? chatInfo
                    ? chatInfo.hasProviderRated
                    : null
                  : chatInfo
                  ? chatInfo.hasStartupRated
                  : null
              }
              isClose={isClose}
              isRate={isRate}
              setIsRate={setIsRate}
              submitChat={submitChat}
              person={true}
              currentBudget={"empty"}
              currentName={data && data.name}
              chatMessage={chatMessage}
              currentUrl={data && data.logoUrl}
              closeChat={closeChat}
              header={true}
            />
          </aside>
        </div>
      )}
    </div>
  );
};

export default ProviderStartup;

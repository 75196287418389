const ChatIcon = ({newChat, fill}) => {
  return (
    <div className={newChat ? "icon-active" : ""}>
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill={fill ? fill : "none"}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.89218 1.34375L3.89404 1.34375H13.394V2L13.3959 1.34375C14.033 1.34557 14.6435 1.59947 15.0941 2.04998C15.5446 2.5005 15.7985 3.11101 15.8003 3.74813L15.8003 3.75V9.75L15.8003 9.75187C15.7985 10.389 15.5446 10.9995 15.0941 11.45C14.6435 11.9005 14.033 12.1544 13.3959 12.1562L13.394 12.1562H8.3802L5.56429 14.504C5.36869 14.6671 5.0964 14.7023 4.86577 14.5943C4.63513 14.4863 4.48779 14.2547 4.48779 14V12.1562H3.89404L3.89218 12.1562C3.25505 12.1544 2.64454 11.9005 2.19402 11.45C1.74351 10.9995 1.48961 10.389 1.4878 9.75187L1.48779 9.75V3.75L1.4878 3.74813C1.48961 3.11101 1.74351 2.5005 2.19402 2.04998C2.64454 1.59947 3.25505 1.34557 3.89218 1.34375ZM13.393 2.65625H3.89508C3.60506 2.65729 3.3272 2.77297 3.1221 2.97806C2.91701 3.18315 2.80134 3.46101 2.80029 3.75103V9.74897C2.80134 10.039 2.91701 10.3168 3.1221 10.5219C3.32718 10.727 3.60502 10.8427 3.89503 10.8438H5.14404C5.50648 10.8438 5.80029 11.1376 5.80029 11.5V12.5984L7.6521 11.0545C7.65225 11.0543 7.6524 11.0542 7.65254 11.0541C7.81547 10.9182 8.02093 10.8437 8.23311 10.8438C8.23315 10.8438 8.23319 10.8438 8.23324 10.8438H13.3931C13.6831 10.8427 13.9609 10.727 14.166 10.5219C14.3711 10.3169 14.4867 10.039 14.4878 9.74901V3.75099C14.4867 3.46098 14.3711 3.18314 14.166 2.97806C13.9609 2.77297 13.683 2.65729 13.393 2.65625Z"
          fill="#052769"
        />
      </svg>
    </div>
  );
};

export default ChatIcon;

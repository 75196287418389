const MessageInfo3 = ({
  title,
  msg,
  imgSrc,
  active,
  openChat,
  productSrc,
  productId,
  chatId,
  budget,
  productTitle,
  showChat,
  isPerson,
  handleisFirst,
  chatClose,
 chatRate,
 
  
}) => {

  return (
    <button
      onClick={() => {
        handleisFirst()
        openChat(chatId, productTitle, productSrc, budget, productId,isPerson,chatClose,chatRate);
        showChat();
      }}
      style={{
        outline: "none",
        cursor: "pointer",
        border: "none",
        textAlign: "left",
      }}
      className={`msg-info ${active ? "bg-grey" : "bg-white"}`}
    >
      <img src={imgSrc} alt="info" className="info-img" />
      <div className="text">
        <h2 className="title clr-primary">{title}</h2>
        {/* <p className="message">{budget ? budget : "***"} </p> */}
      </div>
    </button>
  );
};

export default MessageInfo3;

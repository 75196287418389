import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Pagination = ({cur, goNext, goPrev, goTo, pageLimit}) => {
  return (
    <>
      {pageLimit > 1 ? (
        <div className="pagination-ctrl bg-white">
          <button onClick={goPrev} className="btn btn-sec" id="prev">
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button
            onClick={e => goTo(parseInt(e.target.value))}
            value={cur}
            className="btn"
          >
            {cur}
          </button>
          {pageLimit >= cur + 1 && (
            <button
              onClick={e => goTo(parseInt(e.target.value))}
              value={cur + 1}
              className="btn btn-sec"
            >
              {cur + 1}
            </button>
          )}
          {pageLimit >= cur + 2 && (
            <button
              onClick={e => goTo(parseInt(e.target.value))}
              value={cur + 2}
              className="btn btn-sec"
            >
              {cur + 2}
            </button>
          )}
          <button onClick={goNext} className="btn" id="next">
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Pagination;

/* eslint-disable react-hooks/exhaustive-deps */
import {Link} from "react-router-dom";
import "../assets/styles/css/provider.css";
import Logo from "./Icons/Logo.jsx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {useContext, useEffect, useState} from "react";
import ChatIcon from "./Icons/ChatIcon";
import NotificationIcon from "./Icons/NotificationIcon";
import {UserContext} from "../contexts/UserContextWrap";
import {useHistory} from "react-router";
import axiosInstance from "../hooks/axiosInstance";

const Nav = ({children}) => {
  const [isNavOpen, setisNavOpen] = useState(false);
  const {user, userDispatch} = useContext(UserContext);
  const [showDropDown, setShowDropDown] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const getUserDetails = async () => {
      if (user?.id && user?.userType !== "Admin") {
        try {
          let res = await axiosInstance.get(`/user/getDetails/${user.id}`);
          if (user?.userType === "Provider") {
            let newCategories = await axiosInstance.get(
              `/user/getCategories/${user.id}`
            );
            window.localStorage.setItem(
              "userData",
              JSON.stringify({
                ...res.data.data,
                categories: newCategories.data.data,
              })
            );
          } else {
            window.localStorage.setItem(
              "userData",
              JSON.stringify(res.data.data)
            );
          }
       
        } catch (err) {
          console.log(err);
        }
      }
    };
    getUserDetails();
  }, [user && user.id, user && user?.userType]);

  return (
    <header className="nav bg-white clr-primary">
      <div className="nav-left">
        {user?.userType !== "Admin" && (
          <button
            aria-label="open navbar"
            className="nav-ctrl-btn round-btn btn-sec btn"
            onClick={() => setisNavOpen(!isNavOpen)}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
        )}
        <Logo />
      </div>

      {user?.userType !== "Admin" ? (
        <nav>
          <ul className={`nav_list ${isNavOpen ? "nav-opened" : ""}`}>
            {children}
            {/* <button
            aria-label="close navbar"
            className="nav-ctrl-btn round-btn btn-sec btn"
            onClick={() => setisNavOpen(!isNavOpen)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button> */}
            {user && (
              <li className="nav_link">
                <Link to="/all-services">All Services</Link>
              </li>
            )}
            <li className="nav_link">
              <Link to="/">About Us</Link>
            </li>
          </ul>
          {user && (
            <div className="icon-list-wrap">
              <Link className="nav_link" to="/chats">
                <ChatIcon newChat={false} />
                <span> &nbsp; Chat</span>
              </Link>
              <Link className="nav_link" to="/notifications">
                <NotificationIcon
                  newNotification={user.hasPendingNotification}
                />
                <span>&nbsp; Notifications</span>
              </Link>
            </div>
          )}
          {user ? (
            <div className="drop-down-wrap">
              <button
                onClick={() => setShowDropDown(!showDropDown)}
                className="btn-wrap profile-icon drop-btn btn bg-grey round-btn"
              >
                <img src={user.logoUrl} alt={""} />
                <p className="clr-primary">{user.name}</p>
                <FontAwesomeIcon icon={faChevronDown} />
              </button>
              <div
                className={`drop-down ${showDropDown ? "show-drop-down" : ""}`}
              >
                <Link
                  to={
                    user.userType === "Provider"
                      ? "/provider-profile"
                      : "/startup-profile"
                  }
                >
                  Your Profile
                </Link>
                <button
                  onClick={() => {
                    userDispatch({type: "clearUserData"});
                    localStorage.clear();
                    history.push("/");
                  }}
                >
                  Logout
                </button>
              </div>
            </div>
          ) : (
            <div className="btn-wrap">
              <Link className="btn" to="/">
                Login
              </Link>
              <Link className="btn btn-sec" to="/signup">
                Sign Up
              </Link>
            </div>
          )}
        </nav>
      ) : (
        <div className="btn-wrap">
          <button
            onClick={() => {
              userDispatch({type: "clearUserData"});
              localStorage.clear();
              history.push("/");
            }}
            className="btn"
          >
            Logout
          </button>
        </div>
      )}
    </header>
  );
};

export default Nav;

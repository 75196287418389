const TrashIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3333 4.00001H14.6667V5.33334H13.3333V14C13.3333 14.1768 13.2631 14.3464 13.1381 14.4714C13.0131 14.5964 12.8435 14.6667 12.6667 14.6667H3.33334C3.15653 14.6667 2.98696 14.5964 2.86194 14.4714C2.73691 14.3464 2.66668 14.1768 2.66668 14V5.33334H1.33334V4.00001H4.66668V2.00001C4.66668 1.8232 4.73691 1.65363 4.86194 1.52861C4.98696 1.40358 5.15653 1.33334 5.33334 1.33334H10.6667C10.8435 1.33334 11.0131 1.40358 11.1381 1.52861C11.2631 1.65363 11.3333 1.8232 11.3333 2.00001V4.00001ZM12 5.33334H4.00001V13.3333H12V5.33334ZM6.00001 7.33334H7.33334V11.3333H6.00001V7.33334ZM8.66668 7.33334H10V11.3333H8.66668V7.33334ZM6.00001 2.66668V4.00001H10V2.66668H6.00001Z"
        fill="#FF1111"
      />
    </svg>
  );
};

export default TrashIcon;

import MessageModal from "../components/MessageModal";
import Nav from "../components/Nav";
import mail from "../assets/images/mail.svg";
const ConfirmEmail = () => {
  return (
    <>
      <Nav />
      <div className="pd-login">
        <main className="pd-layout-main pd-layout-main-full bg-blue-200">
          <MessageModal
            icon={mail}
            message="Your email needs to be confirmed for your sign up to be complete, so, click on the confirmation link that will be sent to your email inbox shortly to confirm your email address"
            subTitle="One more thing..."
            title="Verify your Account"
          />
        </main>
      </div>
    </>
  );
};

export default ConfirmEmail;

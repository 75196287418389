import {useForm} from "react-hook-form";
import Nav from "../components/Nav";
import ProgressBar from "../components/ProgressBar";
import {useState} from "react";
import displayMsg from "../components/Message";

import "../assets/styles/css/signup.css";
import PersonalDetails from "../components/PersonalDetails";
import ReviewDetails from "../components/ReviewDetails";
import OrganisationStartUp from "../components/OrganisationStartUp";
import uploadImg from "../hooks/UploadImg";
import {useHistory} from "react-router";
import SubmitBtn from "../components/SubmitBtn";

export const createAccount = async (data, url) => {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
  };
  try {
    const res = await fetch(url, requestOptions);
    const response = await res.json();
    return response;
  } catch (err) {
    return console.log(err);
  }
};
const SignUp = () => {
  const {
    formState: {errors, isValid},
    handleSubmit,
    register,
    control,
    getValues,
  } = useForm({
    mode: "all",
    shouldUnregister: true,
    reValidateMode: "onChange",
  });
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  // register logo file input with react-hook-form
  const logoReg = register("logoUrl", {required: true});

  // form submission handler
  const [reviewData, setreviewData] = useState({});

  const onSubmit = async values => {
    let formValues;
    if (isValid) {
      try {
        setIsLoading(true);
        let url = await uploadImg(values.logoUrl[0], "ui1agzsg");
        formValues = {
          ...values,
          logoUrl: url,
          companyStartDate: new Date(values.companyStartDate).toISOString(),
        };
        let response = await createAccount(
          formValues,
          "https://fintechngr-be-dev.herokuapp.com/startup/signup"
        );
        if (response?.status === "Success") {
          history.push("/confirm-email");
          displayMsg("success", response.message);
        } else {
          displayMsg("error", response.message);
        }
      } catch (err) {
      
        displayMsg("error", err.message);
      } finally {
        setIsLoading(false);
      }
    } else {
      console.log(
        "you have some errors in your form, Please go back to fill them"
      );
    }
  };

  // handle step-by-step form control
  const [percent, setpercent] = useState(0);
  const formname = ["Organization Details", "Personal Details", "Review"];
  const goNext = num => {
    if (isValid) {
      setpercent(percent + num);
      setreviewData(getValues());
    } else {
      displayMsg(
        "error",
        "Error, Please make sure all fields are filled before proceeding"
      );
      console.log(errors, "not valid");
    }
  };

  return (
    <>
      <Nav></Nav>
      <div className="pd-signup">
        <main className="pd-layout-main pd-layout-main-full bg-blue-200">
          <div className="progress-wrap">
            <ProgressBar
              className="progress"
              completed={percent}
              quadrants={2}
              names={["Organization Details", "Personal Details", "Review"]}
            />
          </div>

          <form
            autoComplete="off"
            className={`form ${percent !== 2 ? "bg-white" : ""}`}
            onSubmit={handleSubmit(onSubmit)}
          >
            {percent !== 2 && (
              <>
                <h1 className="clr-primary">Sign Up</h1>
                <div className="progress-wrap progress-wrap-mobile">
                  <ProgressBar
                    className="progress"
                    completed={percent}
                    quadrants={2}
                    names={[
                      "Organization Details",
                      "Personal Details",
                      "Review",
                    ]}
                  />
                </div>
                <p className="clr-placeholder">Step {percent + 1} of 2</p>
                <h2 className="clr-primary">{formname[percent]}</h2>
                <br />
              </>
            )}

            {percent >= 0 && (
              <OrganisationStartUp
                display={percent === 0 ? true : false}
                errors={errors}
                register={register}
                logoReg={logoReg}
                control={control}
              />
            )}
            {percent >= 1 && (
              <PersonalDetails
                errors={errors}
                register={register}
                display={percent === 1 ? true : false}
              />
            )}
            {percent >= 2 && (
              <ReviewDetails
                details={reviewData}
                signupType="START UP"
                display={percent === 2 ? true : false}
              />
            )}
            <div className="btn-wrap">
              <button
                onClick={() => setpercent(percent - 1)}
                className="btn btn-sec"
                type="button"
              >
                {percent === 0 ? "Cancel" : "Back"}
              </button>
              <SubmitBtn
                btnText="Create Account"
                isLoading={isLoading}
                style={{display: percent >= 2 ? "block" : "none"}}
              />
              <button
                style={{display: percent < 2 ? "block" : "none"}}
                type="button"
                onClick={() => goNext(1)}
                className="btn"
              >
                {percent < 1 ? "Next" : "Review"}
              </button>
            </div>
          </form>
        </main>
      </div>
    </>
  );
};

export default SignUp;

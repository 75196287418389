const ChevronIcon = ({rotate}) => {
  return (
    <svg
      style={{transform: `rotate(${rotate}deg)`}}
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.644043" width="20" height="20" rx="10" fill="#F1F5FE" />
      <path
        d="M6.64404 8L10.644 12L14.644 8"
        stroke="#052769"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChevronIcon;

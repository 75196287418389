import CardDetails from "./CardDetails";
const ReviewDetails = ({
  details: {membershipId, name, years, repName, email, phoneNo, logoUrl},
  signupType,
  display,
}) => {
  return (
    <div
      style={{display: display ? "block" : "none"}}
      className="reveiw-details"
    >
      <h1 className="clr-primary">Review Details</h1>

      <h2 className="clr-primary">Organization Details</h2>
      <CardDetails
        label={signupType}
        name={name}
        img={logoUrl}
        membershipID={membershipId}
        years={years}
      />
      <br />
      <h2 className="clr-primary">Personal Details</h2>
      <div className="p-details">
        <div>
          <p className="clr-placeholder">FULL NAME</p>
          <h3>{repName}</h3>
        </div>
        <div>
          <p className="clr-placeholder">EMAIL ADDRESS</p>
          <h3>{email} </h3>
        </div>
        <div>
          <p className="clr-placeholder">PHONE NUMBER</p>
          <h3>{phoneNo}</h3>
        </div>
        <input type="hidden" name="" />
      </div>
      <br />
    </div>
  );
};

export default ReviewDetails;

/* eslint-disable react-hooks/exhaustive-deps */
import Nav from "../../components/Nav";
import {useForm} from "react-hook-form";
import Input from "../../components/Input";
import "../../assets/styles/css/accounts.css";
import {useEffect, useState} from "react";
import displayMsg from "../../components/Message";
import Preloader from "../../components/Preloader";
import NoProduct from "../../components/NoProduct";
import bagIcon from "../../assets/images/bagIcon.svg";
// import TrashIcon from "../../components/Icons/TrashIcon";
import axios from "axios";
import {Link} from "react-router-dom";
import SearchInput from "../../components/SearchInput";
import axiosInstance from "../../hooks/axiosInstance";
import SubmitBtn from "../../components/SubmitBtn";

const AddService = () => {
  const {
    register: regService,
    formState: serviceFormState,
    handleSubmit: handleServiceForm,
  } = useForm({
    reValidateMode: "onSubmit",
    mode: "onSubmit",
  });

  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const searchData = e => {
    const {value} = e.target;
    setSearchValue(value);
    // form is valid
    if (!!value.trim()) {
      let newData =
        realData.length &&
        realData.filter(each => {
          return each.name.toLowerCase().includes(value.toLowerCase());
        });
      setRealData2(newData);
    } else {
      setRealData2([]);
    }
  };
  const addService = values => {
    setisLoading(true);
    axiosInstance
      .post("/admin/category/create", values)
      .then(res => {
        displayMsg("success", "Service Category Added");
        setRealData([...realData, res.data.data]);
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx
          displayMsg("error", error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          displayMsg("error", error.message);
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);
  const [realData, setRealData] = useState([]);
  const [realData2, setRealData2] = useState([]);

  const getCategories = () => {
    setIsPending(true);
    const abortCont = new AbortController();
    axios
      .get("https://fintechngr-be-dev.herokuapp.com/user/getCategories")
      .then(json => {
        setIsPending(false);
        if (json.data.status === "Success") {
          setRealData(json.data.data);
          setError(null);
        } else {
          setError(json.message);
          displayMsg("error", json.message);
        }
      })
      .catch(err => {
        if (err.name === "AbortError") {
          console.log("fetch aborted");
        } else if (err.message === "Failed to fetch") {
          setIsPending(false);
          setError(err.message + ", you might want to check your connection");
          displayMsg("error", err.message);
        } else {
          // auto catches network / connection error
          setIsPending(false);
          setError(err.message);
          displayMsg("error", err.message);
        }
      });

    // abort the fetch
    return () => abortCont.abort();
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className="accounts bg-blue-200">
      <Nav />
      {isPending ? (
        <Preloader isLoading={isPending} />
      ) : (
        <div className="mainPage">
          <div className="top">
            <div className="tabs">
              <Link to="/admin/account">
                <h2>All Accounts</h2>
              </Link>
              <Link className="active" to="/admin/services">
                <h2>List Of Services</h2>
              </Link>
            </div>
            <br />
            <div className="titleSearch services-form-wrap">
              <div className="addservice">
                <h4 className="clr-primary">Add a service</h4>
                <form
                  //   style={{display: "flex", minWidth: "500px"}}
                  action=""
                  onSubmit={handleServiceForm(addService)}
                >
                  <Input
                    type="text"
                    name="name"
                    placeholder="Add a service..."
                    id="addService"
                    className="search-box"
                    register={regService}
                    errors={serviceFormState.errors}
                    errMsg="invalid input"
                  />
                  <SubmitBtn
                    btnText="+ Add Service"
                    isLoading={isLoading}
                    style={{minWidth: "150px", marginLeft: "15px"}}
                  />
                </form>
              </div>
              <SearchInput
                placeholder="Search for services..."
                searchData={searchData}
                searchValue={searchValue}
              />
            </div>
          </div>
          {error ? (
            <NoProduct icon={bagIcon} msg={error} />
          ) : (
            <div className="accountList">
              {realData2.length === 0 && realData ? (
                <>
                  {realData.map((item, i) => (
                    <div key={i} className="eachService service">
                      <h3 className="clr-primary">{item.name}</h3>
                      {/* <button className="btn btn-sec">
                          <TrashIcon />
                        </button> */}
                    </div>
                  ))}
                </>
              ) : realData2.length > 0 ? (
                realData2.map((item, i) => (
                  <div key={i} className="eachService service">
                    <h3 className="clr-primary">{item.name}</h3>
                    {/* <button className="btn btn-sec">
                      <TrashIcon />
                    </button> */}
                  </div>
                ))
              ) : (
                <NoProduct icon={bagIcon} msg="Oops! No Result..." />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AddService;

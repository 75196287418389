import Input from "../components/Input";
import {useForm} from "react-hook-form";
import UploadFile from "../components/Upload";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import UploadMultipleFile from "../components/UploadMultiFiles";

const LayoutProfile = () => {
  const {formState, handleSubmit, register, getValues, setValue} = useForm({
    mode: "all",
    shouldUnregister: true,
    reValidateMode: "onChange",
  });

  const onSubmit = values => {
    // form is valid
    console.log(values, getValues());
  };
  const logoReg = register("logo", {required: true});
  const MultiReg = register("multi", {required: true});

  return (
    <div className="pd-layout pd-fintech-provider">
      {/* the layout with a main and sidebar add a class of pd-layout-main-full to fill the whole screen*/}
      <main className="pd-layout-main">
        This is a demo page of all the resuable components
        <br />
        <form action="" onSubmit={handleSubmit(onSubmit)}>
          {/* upload file component */}
          <UploadFile
            text="Upload Your Logo"
            name="logo"
            id="logoUpload"
            errors={formState.errors}
            fileReg={logoReg}
            errMsg="invalid file input"
          />

          {/* input component demo */}
          <Input
            type="password"
            name="password"
            label="Password"
            placeholder="Enter password"
            id="password"
            register={register}
            iconPlaceholder={faLock}
            errors={formState.errors}
            errMsg="invalid password input"
          />
          <UploadMultipleFile
            text="Upload Your Photos"
            name="multi"
            id="multi"
            errors={formState.errors}
            fileReg={MultiReg}
            errMsg="invalid file input"
            setValue={setValue}
          />
          <button type="submit" className="btn">
            Submit
          </button>
        </form>
        {/* btn classes */}
        <button className="btn">Primary Btn</button>
        <button className="btn btn-sec">Secodary Btn</button>
      </main>
      <aside className="pd-layout-sidebar">
        <h2>sidebar</h2>
      </aside>
    </div>
  );
};

export default LayoutProfile;

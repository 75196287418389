const MessageInfo = ({title, msg, imgSrc, active}) => {
  return (
    <div className={`msg-info ${active ? "bg-grey" : "bg-white"}`}>
      <img src={imgSrc} alt="info" className="info-img" />
      <div className="text">
        <h2 className="title clr-primary">{title}</h2>
        <p className="message">{msg} </p>
      </div>
    </div>
  );
};

export default MessageInfo;

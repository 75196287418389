import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import InputErrorMsg from "./InputErrorMsg";
import EyeClosedIcon from "./Icons/EyeClosedIcon";

const trapSpacesForRequiredFields = (value, notRequired) => {
  if (notRequired !== true) {
    return !!value.trim();
  }
};

const Input = ({
  type,
  label,
  id,
  name,
  placeholder,
  errors,
  register,
  isDisabled,
  errMsg,
  iconPlaceholder,
  className,
  notRequired,
}) => {
  const [isTypePassword, setisTypePassword] = useState(true);

  return (
    <div className={`form-group ${className}`}>
      {label && <label htmlFor={id}>{label}</label>}
      <div
        className={`input-icon-wrap ${
          iconPlaceholder ? "icon-placeholder" : ""
        } ${errors[name] ? "is-invalid" : ""}`}
      >
        {iconPlaceholder && (
          <button disabled className="icon icon-left">
            <FontAwesomeIcon icon={iconPlaceholder} />
          </button>
        )}
        {type === "password" ? (
          <>
            <input
              autoComplete="off"
              name={name}
              disabled={isDisabled}
              type={isTypePassword ? "password" : "text"}
              id={id}
              {...register(name, {
                required: notRequired ? false : true,
              })}
              placeholder={placeholder}
            />
            <button
              disabled={isDisabled}
              onClick={() => setisTypePassword(prev => !prev)}
              type="button"
              className="icon"
            >
              {isTypePassword === true ? (
                <FontAwesomeIcon icon={faEye} />
              ) : (
                <EyeClosedIcon />
              )}
            </button>
          </>
        ) : (
          <>
            <input
              autoComplete="off"
              disabled={isDisabled}
              type={type}
              id={id}
              {...register(name, {
                required: notRequired ? false : true,
                validate: value =>
                  trapSpacesForRequiredFields(value, notRequired),
              })}
              name={name}
              placeholder={placeholder}
            />
          </>
        )}
      </div>

      {errMsg && errors[name] && <InputErrorMsg errMsg={errMsg} />}
    </div>
  );
};

export default Input;

import MessageModal from "../components/MessageModal";
import Nav from "../components/Nav";
import {faCheck, faCommentSlash} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../hooks/axiosInstance";
import displayMsg from "../components/Message";
import { useState } from "react";
import Preloader from "../components/Preloader";
import NoProduct from "../components/NoProduct";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const PaymentSuccessful = () => {

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const transaction_id = urlParams.get("transaction_id");


  const [isLoading,setIsLoading] = useState(false)
  const [isSuccess,setIsSuccess] = useState(false)
  const verify = () => {
    setIsLoading(true)
    const payload = {
      transactionId:transaction_id
    };
  
    axiosInstance
      .post("/startup/verifyPayment2", payload)
      .then(result => {
     
        setIsLoading(false)
        if (result.data.status === "Success") {
          displayMsg("success", "Successful")
          setIsSuccess(true)
        } else {
          displayMsg("error", result.message);
        }
      })
      .catch(error => {
        setIsLoading(false)
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx
          displayMsg("error", error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          displayMsg("error", error.message);
          console.log("Error", error.message);
        }
      });
  };
  useState(()=>{
    verify()
  },[])


 
  return (
    <>
      <Nav />

      {
        isLoading ?
        <Preloader/>
         :
         isSuccess?  <div className="pd-login">
         <main className="pd-layout-main pd-layout-main-full bg-blue-200">
           <MessageModal
             icon={faCheck}
             faIcon={true}
             title="Payment Successful"
           ></MessageModal>
         </main>
       </div>:  
      
       <NoProduct msg="Payment Unsuccessful">
                    <FontAwesomeIcon icon={faCommentSlash} />
                  </NoProduct>

        
      
      }
     
    </>
  );
};

export default PaymentSuccessful;

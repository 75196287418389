import Upload from "./Upload";
import Input from "./Input";
import "../assets/styles/css/signup.css";
import Checkbox from "./Checkbox";
import Textbox from "./Textbox";

// import {Link} from "react-router-dom";

const OrganisationStartUp = ({register, errors, display, control, logoReg}) => {
  return (
    <div style={{display: display ? "block" : "none"}}>
      <p className="clr-primary">What best descibes you?</p>
      <div className="checkbox-wrapper">
        <Checkbox
          id="startup"
          name="signupType"
          value="Startup"
          linkTo="/signup"
          isChecked={true}
          label="Startup"
        />
        <Checkbox
          name="signupType"
          isChecked={false}
          value="Provider"
          id="Provider"
          linkTo="/signup/provider"
          label="Service Provider"
        />
      </div>
      <Upload
        text="Upload Your Logo"
        name="logoUrl"
        id="logoUrl"
        errors={errors}
        fileReg={logoReg}
        errMsg="invalid file input"
      />
      <br />
      <Input
        type="text"
        name="membershipId"
        label="FintechNGR Membership ID"
        placeholder="FSTR-23352"
        id="membershipId"
        register={register}
        errors={errors}
        errMsg="invalid membership ID"
      />
      <Input
        type="text"
        name="name"
        label="Name of Startup"
        placeholder="Agege inc."
        id="startupName"
        register={register}
        errors={errors}
        errMsg="invalid input"
      />
      <Input
        type="date"
        name="companyStartDate"
        label="Years Active"
        placeholder="1"
        id="years"
        register={register}
        errors={errors}
        errMsg="invalid input"
      />
      <Textbox
        name="about"
        label="Company Description"
        placeholder="Write something..."
        register={register}
        errors={errors}
        errMsg="invalid field"
      />
    </div>
  );
};

export default OrganisationStartUp;
